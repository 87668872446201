import React from "react";
import Header from "../../organisms/Header/component";

import "./style.css";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
}

const Privacy: React.FC<Props> = ({isLogin, isAuthLoading}) => {
    return (
        <div className="Privacy">
            <Header isLogin={isLogin} isAuthLoading={isAuthLoading}/>
            <div className="Privacy__main">
                <h1>プライバシーポリシー</h1>
                <h3>１：序文</h3>
                <p>本プライバシーポリシーは、「事業承継サポート」（以下「当サイト」）がユーザーの個人情報をどのように収集、使用、保護するかを説明するものです。当サイトを利用することにより、本プライバシーポリシーに同意したものとみなされます。</p>
                <h3>２：収集する情報</h3>
                <p>個人情報: ユーザーの名前、メールアドレス、住所、電話番号など。</p>
                <p>非個人情報: クッキー、IPアドレス、ブラウザの種類、アクセス日時など。</p>
                <h3>３：情報の収集方法</h3>
                <p>直接収集: ユーザーが登録フォームやお問い合わせフォームを通じて提供する情報。</p>
                <p>自動収集: クッキーやログファイル、ウェブビーコンを使用して自動的に収集する情報。</p>
                <h3>４：情報の使用目的</h3>
                <p>サービス提供</p>
                <p>カスタマーサポート</p>
                <p>マーケティング（ユーザーの同意がある場合）</p>
                <p>サービスの改善</p>
                <h3>５：情報の共有と提供</h3>
                <p>第三者への提供: ユーザーの同意を得た上で第三者に情報を提供する場合や、法律に基づく場合。</p>
                <p>サービス提供者: クラウドサービスや分析サービスなどの第三者サービス提供者に情報を共有する場合。</p>
                <h3>６：情報の保護</h3>
                <p>当サイトは、ユーザーの個人情報を保護するために適切な技術的、物理的、管理的なセキュリティ対策を講じます。</p>
                <h3>７：ユーザーの権利</h3>
                <p>ユーザーは、自分の個人情報の確認、訂正、削除を要求する権利があります。これらの要求は、以下の連絡先にて受け付けます。</p>
                <h3>８：プライバシーポリシーの変更</h3>
                <p>本プライバシーポリシーを変更する場合、当サイトはその変更内容を本ページに掲載します。変更後も当サイトを利用することにより、変更に同意したものとみなされます。</p>
                <h3>９：問い合わせ先</h3>
                <p>メール：<a href="mailto:support@65diary.jp">support@65diary.jp</a></p>
            </div>
        </div>
    )
}

export default Privacy;