import React, {useState, useEffect} from "react";
import { Navigate, useParams } from 'react-router-dom';
import { Button, CircularProgress } from "@mui/material";
import { Textarea } from "@mui/joy";

import { defaultUserUrl } from "../../../lib/const/url";
import { Message as MessageType } from "../../../fetch/messages/get/entity";
import { fetchGetMessage } from "../../../fetch/messages/get";
import { QueryBuilder } from "../../../fetch/messages/get/queryBuilder";
import { Question } from "../../../fetch/questions/getAll/entity";
import { QuestionType } from "../../../fetch/questionTypes/getAll/entity";
import { RoomUserEntity, User } from "../../../fetch/users/getRoomUser/entity";
import { User as CookieUser } from "../../../fetch/users/cookieUser/entity";
import { fetchUpdateMessage } from "../../../fetch/messages/update";
import { QueryBuilder as UpdateMessageQueryBuilder } from "../../../fetch/messages/update/queryBuilder";
import { fetchCreatePrompt } from "../../../fetch/prompts/create";
import { QueryBuilder as CreatePromptQueryBuilder } from "../../../fetch/prompts/create/queryBuilder";
import { fetchGetPrompt } from "../../../fetch/prompts/get";
import { QueryBuilder as GetPromptQueryBuilder } from "../../../fetch/prompts/get/queryBuilder";

import Header from "../../organisms/Header/component";

import "./style.css";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
    roomID: string;
    questions: Question[];
    questionTypes: QuestionType[];
    cookieUser: CookieUser;
    getRoomConfirmedUser: (roomID: string) => Promise<RoomUserEntity>;
    signOut: () => Promise<boolean>;
}

const Message: React.FC<Props> = ({
    isLogin,
    isAuthLoading,
    roomID,
    questions,
    questionTypes,
    cookieUser,
    getRoomConfirmedUser,
    signOut
}) => {
    const { messageID } = useParams<{messageID: string}>();
    const [message, setMessage] = useState<MessageType>({} as MessageType);
    const [users, setUsers] = useState<User[]>([]);
    const user = users.find((user) => user.id === message.userID);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editMessage, setEditMessage] = useState<string>(message.content);
    const [isPromptLoading, setIsPromptLoading] = useState<boolean>(false);
    const [promptResult, setPromptResult] = useState<string>("");

    const updateMessage = async() => {
        if (message.userID !== cookieUser.id) return;
        setIsEditing(true);
        const queryBuilder = new UpdateMessageQueryBuilder();
        queryBuilder.setContent(editMessage);
        queryBuilder.setMessageID(message.id);
        queryBuilder.setRoomID(roomID);
        await fetchUpdateMessage(queryBuilder.build());
        setMessage((prev) => ({...prev, content: editMessage}));
        setIsEditing(false)
    }

    const createPrompt = async () => {
        if (message.userID !== cookieUser.id) return;
        setIsPromptLoading(true);
        const queryBuilder = new CreatePromptQueryBuilder();
        queryBuilder.setMessageID(message.id);
        queryBuilder.setRoomID(roomID);
        queryBuilder.setPrompt(message.content);
        const resultEntity = await fetchCreatePrompt(queryBuilder.build());
        console.log(resultEntity);
        setIsPromptLoading(false);
        setPromptResult(resultEntity.data.response);
    }

    useEffect(() => {
        async function doGet() {
            const queryBuilder = new QueryBuilder();
            queryBuilder.setID(Number(messageID));
            queryBuilder.setRoomID(roomID);
            const result = await fetchGetMessage(queryBuilder.build());
            setMessage(result.data);
            setEditMessage(result.data.content);
        }
        doGet();
    }, [roomID]);

    useEffect(() => {
        async function doGet() {
            const result = await getRoomConfirmedUser(roomID);
            setUsers(result.data);
        }
        doGet();
    }, []);

    useEffect(() => {
        async function doGet() {
            if (!roomID || !message.id || !message.questionID || promptResult) return;
            const queryBuilder = new GetPromptQueryBuilder();
            queryBuilder.setMessageID(message.id);
            queryBuilder.setQuestionID(message.questionID);
            queryBuilder.setRoomID(roomID);
            const result = await fetchGetPrompt(queryBuilder.build());
            setPromptResult(result.data.response);
        }
        doGet();
    }, [roomID, message])

    if (!isLogin && !isAuthLoading) return <Navigate to="/users/sign_in"/>
    return (
        <div className="Message">
            <Header isLogin={isLogin} isAuthLoading={isAuthLoading} signOut={signOut}/>
            <div className="Message__main">
                <h3>メッセージ情報</h3>
                { user &&
                    <div className="Message__user">
                        <img src={user.thumbnailImage || defaultUserUrl} width="20" height="20" alt={user.fullName}/>
                        { user.fullName }さん
                    </div>
                }
                { message.questionID
                ?
                    <div className="Message__question">
                        <Button color="warning" variant="contained" size="small" className="Message__questionTypes">
                            {questionTypes.find((qt) => qt.id === questions.find((q) => q.id === message.questionID)?.typeID)?.title || "該当カテゴリなし"}
                        </Button>
                        <h3 style={{margin: "10px 0"}}>
                            「{questions.find((q) => q.id === message.questionID)?.title || ""}」 に関する事業継承に関する知見
                        </h3>
                        {questions.find((q) => q.id === message.questionID)?.content || ""} など
                    </div>
                : null
                }
                メッセージ内容：
                { cookieUser.id === message.userID &&
                <>
                    {isEditing
                    ?
                    <Button
                        color="error"
                        variant="contained"
                        size="small"
                        onClick={updateMessage}
                    >
                        更新する
                    </Button>
                    :
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => setIsEditing(true)}
                    >
                        更新する
                    </Button>
                    }
                </>
                }
                <div className="Message__content">
                    { isEditing
                    ?
                        <Textarea
                            value={editMessage}
                            maxRows={10}
                            className="Message__form"
                            onChange={(e) => setEditMessage(e.target.value)}
                        />
                    :
                        <>
                        {message.content?.split("\n").map((line, index) => {
                            return <React.Fragment key={index}>{line}<br/></React.Fragment>
                        })}
                        </>
                    }
                </div>
                { !!message.questionID &&
                    <>
                    <p>ChatGPTの意見</p>
                    <div className="Message__content">
                        { isPromptLoading
                            ? <CircularProgress/>
                            : promptResult ?
                            promptResult.split("\n").map((line, index) => {
                                return (<React.Fragment key={index}>
                                    {line}<br/>
                                </React.Fragment>)
                            })
                            : "まだChatGPTに聞いていません"
                        }
                    </div>
                    <br/>
                    { message.userID === cookieUser.id &&
                        <Button
                            onClick={createPrompt}
                            color="primary"
                            variant="contained"
                            disabled={isPromptLoading}
                        >
                            ChatGPTの意見を聞く（長いと2, 3分程度かかります）
                        </Button>
                    }
                    </>
                }
            </div>
        </div>
    )
}

export default Message;