import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";

import { Button, TextField } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';

import Header from "../../organisms/Header/component";

import "./style.css";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
    signIn: (email: string, password: string) => Promise<boolean>;
}

type FormProps = {
    email: string;
    password: string;
}

const SignIn: React.FC<Props> = ({isLogin, signIn, isAuthLoading}) => {
    const [signInForm, setSignInForm] = useState<FormProps>({} as FormProps);
    const [isSnackbarDisplayed, setIsSnackbarDisplayed] = useState<boolean>(false);
    const onChangeForm = (type: keyof FormProps, value: string) => {
        setSignInForm((prev) => {
            return {...prev, [type]: value};
        })
    }
    const onClickButton = async() => {
        const signInResult = await signIn(signInForm.email || "", signInForm.password || "");
        if (!signInResult) {
            setIsSnackbarDisplayed(true);
            return;
        }
    }
    const onClickCloseSnackbar = () => {
        setIsSnackbarDisplayed(false);
    }
    if (isLogin && !isAuthLoading) return <Navigate to="/users/followup"/>
    return (
        <div className="SignIn">
            <Header isLogin={isLogin} isAuthLoading={isAuthLoading} />
            <div className="SignIn__main">
                <h3>ログインする</h3>
                <p>メールアドレス</p>
                <TextField
                    color="warning"
                    variant="outlined"
                    className="SignIn__form"
                    type="email"
                    value={signInForm.email || ""}
                    onChange={(e) => onChangeForm("email", e.target.value)}
                />
                <br/>
                <p>パスワード</p>
                <TextField
                    color="warning"
                    variant="outlined"
                    className="SignIn__form"
                    type="password"
                    value={signInForm.password || ""}
                    onChange={(e) => onChangeForm("password", e.target.value)}
                />
                <br/>
                <Button color="warning" variant="outlined" onClick={onClickButton} className="SignIn__button">
                    { isAuthLoading
                    ? <CircularProgress color="info"/>
                    : <>ログインする</>
                    }
                </Button>
                <br/>
                <Link to="/users/sign_up">登録する</Link>
                <Snackbar
                    open={isSnackbarDisplayed}
                    autoHideDuration={2000}
                    message="ログインに失敗しました"
                    onClose={onClickCloseSnackbar}
                />
            </div>
        </div>
    )
}

export default SignIn;