import { CookieUserEntity, CookieUserError } from "./entity"
import { ResponseMapper } from "./responseMapper"
import { fetchUrl } from "../../../lib/const/url";

export async function fetchCookieUser(): Promise<CookieUserEntity> {
    try {
        const response = await fetch(`${fetchUrl}/confirm_users/cookieUser`, {
            method: "GET",
            credentials: "include"
        }).then((data) => data.json());
        if (response.message) {
            return new CookieUserError(response.message);
        }
        const entity = new ResponseMapper(response).convert();
        return entity;
    } catch(e) {
        console.log(e);
        return new CookieUserError(JSON.stringify(e));
    }
}