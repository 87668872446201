export type Message = {
    id: number;
    userID: string;
    questionID: number;
    imageUrl: string;
    content: string;
    createdAt: string;
}

export class CreateImageMessageEntity {
    data: Message;
    error?: string;
    constructor(data: Message, error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

const emptyMessage = {
    id: 0,
    userID: "",
    questionID: 0,
    imageUrl: "",
    content: "",
    createdAt: ""
};

export class CreateImageMessageEmpty extends CreateImageMessageEntity {
    constructor() {
        super(emptyMessage)
    }
}

export class CreateImageMessageError extends CreateImageMessageEntity {
    constructor(errorMessage: string) {
        super(emptyMessage, errorMessage)
    }
}