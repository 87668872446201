export type User = {
    id: string;
    fullName: string;
    userType: number;
    isEmailConfirmed: boolean;
}

export class SignUpEntity {
    data: User;
    error?: string;
    constructor(user: User, error?: string) {
        this.data = user;
        if(error) this.error = error;
    }
}

export class SignUpEmpty extends SignUpEntity {
    constructor() {
        super({id: "", fullName: "", userType: 0, isEmailConfirmed: false})
    }
}

export class SignUpError extends SignUpEntity {
    constructor(errorMessage: string) {
        super({id: "", fullName: "", userType: 0, isEmailConfirmed: false}, errorMessage)
    }
}