import { GetRoomMessageEntity, GetRoomMessageEmpty } from "./entity";
import { toInt } from "../../../lib/helper/valueCast";

export class ResponseMapper {
    data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert(): GetRoomMessageEntity {
        return this.data.Messages && Array.isArray(this.data.Messages)
        ? new GetRoomMessageEntity(
            this.data.Messages.map((d: any) => ({
                id: toInt(d?.ID),
                userID: String(d?.UserID),
                questionID: toInt(d?.QuestionID),
                imageUrl: String(d?.ImageUrl),
                content: String(d?.Content),
                createdAt: String(d?.CreatedAt)
            }))
        )
        : new GetRoomMessageEmpty();
    }
}