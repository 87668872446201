import React, {useState} from "react";
import { Link, Navigate, useParams } from "react-router-dom";

import { Room } from "../../../fetch/rooms/getConfirmed/entity";
import { fetchInviteRoom } from "../../../fetch/rooms/invite";
import { QueryBuilder } from "../../../fetch/rooms/invite/queryBuilder";

import Header from "../../organisms/Header/component";

import "./style.css"
import { Button, TextField } from "@mui/material";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
    rooms: Room[];
    currentUserID: string;
    signOut: () => Promise<boolean>;
}

type UserProps = {
    id: number;
    value: string;
}

const InviteRegisteredUser: React.FC<Props> = ({
    isLogin,
    isAuthLoading,
    rooms,
    currentUserID,
    signOut
}) => {
    const [pageComplete, setPageComplete] = useState<boolean>(false);
    const [userIds, setUserIds] = useState<UserProps[]>([]);
    const { roomID } = useParams<{roomID: string}>();
    const currentRoom = rooms.map((r) => r.id).includes(roomID || "");
    const addUserId = () => {
        let randomNumber: number;
        while(true) {
            randomNumber = Math.floor(Math.random() * 10000);
            if (!userIds.find((u) => u.id === randomNumber)) break;
        }
        setUserIds((prev) => {
            return [...prev, {id: randomNumber, value: ""}]
        })
    }
    const onChangeUserId = (id: number, value: string) => {
        setUserIds((prev) => {
            return prev.map((p) => {
                if (p.id === id) return {...p, value};
                return p;
            })
        })
    }
    const onClickDelete = (id: number) => {
        setUserIds((prev) => {
            return prev.filter((p) => {
                return p.id !== id;
            });
        });
    }
    const onClickRegister = () => {
        userIds.forEach(async(user) => {
            const queryBuilder = new QueryBuilder();
            queryBuilder.setRoomID(roomID || "");
            queryBuilder.setUserID(user.value);
            await fetchInviteRoom(queryBuilder.build());
        });
        setPageComplete(true);
    }
    if (pageComplete) return <Navigate to="/mypage" />
    if (!currentRoom) return <Navigate to="/mypage"/>
    if (rooms.find((r) => r.id === roomID)?.ownerID !== currentUserID) return <Navigate to="/mypage"/>
    return (
        <div className="InviteRegisteredUser">
            <Header isLogin={isLogin} isAuthLoading={isAuthLoading} signOut={signOut}/>
            <div className="InviteRegisteredUser__main">
                <Link to="/mypage">戻る</Link>
                <div className="InviteRegisteredUser__content">
                    <h3>「ユーザーを追加する」を押して、IDを入力してください</h3>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={addUserId}
                    >
                        ユーザーを追加する
                    </Button>
                    { userIds.map((uid, index) => {
                        return (
                            <div key={uid.id} className="InviteRegisteredUser__form">
                                <span>ID: {index + 1}</span>
                                <br/>
                                <TextField
                                    value={userIds.find((p) => p.id === uid.id)?.value}
                                    onChange={(e) => onChangeUserId(uid.id, e.target.value)}
                                    className="InviteRegisteredUser__textField"
                                />
                                <br/>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => onClickDelete(uid.id)}
                                >
                                    削除する
                                </Button>
                            </div>
                        )
                    }) }
                </div>
                <br/>
                <Button
                    variant="contained"
                    color="warning"
                    onClick={onClickRegister}
                >
                    登録する
                </Button>
            </div>
        </div>
    );
}

export default InviteRegisteredUser;