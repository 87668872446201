import React, {useState, useEffect} from "react";
import { Link, Navigate } from "react-router-dom";

import { Button, Checkbox, MenuItem, TextField } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';

import Header from "../../organisms/Header/component";

import "./style.css";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
    signUp: (email: string, password: string, fullName: string, userType: number) => Promise<boolean>;
}

type FormProps = {
    email: string;
    password: string;
    fullName: string;
    userType: string;
}

const SignUp: React.FC<Props> = ({isLogin, isAuthLoading, signUp}) => {
    const [signUpForm, setSignUpForm] = useState<FormProps>({userType: "1"} as FormProps);
    const [isSnackbarDisplayed, setIsSnackbarDisplayed] = useState<boolean>(false);
    const [agreement, setAgreement] = useState(false);
    const onChangeForm = (type: keyof FormProps, value: string) => {
        setSignUpForm((prev) => {
            return {...prev, [type]:value};
        })
    }
    const onChangeSelect = (event: SelectChangeEvent) => {
        setSignUpForm((prev) => {
            return {...prev, userType: event.target.value as string}
        })
    }
    const onClickButton = async() => {
        if (!agreement) return;
        if (!signUpForm.email || !signUpForm.password || !signUpForm.fullName || !signUpForm.userType){
            setIsSnackbarDisplayed(true);
            return;
        }
        const signUpResult = await signUp(signUpForm.email, signUpForm.password, signUpForm.fullName, Number(signUpForm.userType));
        if (!signUpResult) {
            setIsSnackbarDisplayed(true);
            return;
        }
    }
    const onClickCloseSnackbar = () => {
        setIsSnackbarDisplayed(false);
    }
    useEffect(() => {
        const lastAgreement = Boolean(localStorage.getItem("65diaryAgreement"));
        setAgreement(lastAgreement);
    }, [])
    if (isLogin && !isAuthLoading) return <Navigate to="/users/followup"/>
    return(
        <div className="SignUp">
            <Header isLogin={isLogin} isAuthLoading={isAuthLoading}/>
            <div className="SignUp__main">
                <h3>会員登録する</h3>
                <p>メールアドレス</p>
                <TextField
                    color="warning"
                    variant="outlined"
                    className="SignUp__form"
                    type="email"
                    value={signUpForm.email || ""}
                    onChange={(e) => onChangeForm("email", e.target.value)}
                />
                <p>お名前</p>
                <TextField
                    color="warning"
                    variant="outlined"
                    className="SignUp__form"
                    value={signUpForm.fullName || ""}
                    onChange={(e) => onChangeForm("fullName", e.target.value)}
                />
                <p>親か子供か</p>
                <Select
                    value={signUpForm.userType}
                    onChange={onChangeSelect}
                >
                    <MenuItem value="1">親</MenuItem>
                    <MenuItem value="2">子供</MenuItem>
                    <MenuItem value="3">関係者</MenuItem>
                </Select>
                <p>パスワード</p>
                <TextField
                    color="warning"
                    variant="outlined"
                    className="SignUp__form"
                    type="password"
                    value={signUpForm.password || ""}
                    onChange={(e) => onChangeForm("password", e.target.value)}
                />
                <p>
                    <Checkbox onChange={(e) => {setAgreement(e.target.checked);}} checked={agreement}/><Link to="/privacy">プライバシーポリシー</Link>・<Link to="/terms">利用規約</Link>に同意して始める
                </p>
                <Button color="warning" variant="outlined" onClick={onClickButton} className="SignUp__button" disabled={!agreement}>
                    { isAuthLoading
                    ? <CircularProgress color="info"/>
                    : <>登録する</>
                    }
                </Button>
                <br/>
                <Link to="/users/sign_in">ログインする</Link>
                <Snackbar
                    open={isSnackbarDisplayed}
                    autoHideDuration={2000}
                    message="会員登録に失敗しました"
                    onClose={onClickCloseSnackbar}
                />
            </div>
        </div>
    )
}

export default SignUp;