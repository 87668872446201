export type Message = {
    id: number;
    userID: string;
    questionID: number;
    imageUrl: string;
    content: string;
    createdAt: string;
}

export class GetRoomQuestionMessageEntity {
    data: Message[];
    error?: string;
    constructor(data: Message[], error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

export class GetRoomQuestionMessageEmpty extends GetRoomQuestionMessageEntity{
    constructor(){
        super([]);
    }
}

export class GetRoomQuestionMessageError extends GetRoomQuestionMessageEntity {
    constructor(errorMessage: string) {
        super([], errorMessage);
    }
}