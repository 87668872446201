export class QueryBuilder {
    private query: any;
    constructor() {
        this.query = {}
    }
    setRoomID(roomID: string) {
        this.query.roomID = roomID;
    }
    setUserID(userID: string) {
        this.query.userID = userID;
    }
    build() {
        return JSON.stringify(this.query);
    }
}