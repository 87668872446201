import { CreateEntity, CreateEmpty} from "./entity";
import { toInt } from "../../../lib/helper/valueCast";

export class ResponseMapper{
    private data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert(): CreateEntity {
        return this.data
        ?
        new CreateEntity({
            validator: String(this.data?.Validator),
            userId: toInt(this.data?.UserID)
        })
        : new CreateEmpty();
    }
}