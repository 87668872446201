import { fetchUrl } from "../../../lib/const/url";
import { CreatePromptEmpty, CreatePromptEntity, CreatePromptError } from "./entity";


export async function fetchCreatePrompt(query: any): Promise<CreatePromptEntity> {
    try {
        const response = await fetch(`${fetchUrl}/prompts/create`, {
            method: "POST",
            body: query,
            credentials: "include"
        }).then((data) => data.json());
        console.log(response);
        if (!response.Response) return new CreatePromptEmpty();
        const entity = new CreatePromptEntity({response: response.Response});
        return entity;
    } catch (e) {
        console.log(e);
        return new CreatePromptError(JSON.stringify(e));
    }
}