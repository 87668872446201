import { SignInEmpty, SignInEntity } from "./entity"
import { toInt } from "../../../lib/helper/valueCast";

export class ResponseMapper{
    private data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert(): SignInEntity {
        return this.data
        ?
            new SignInEntity({
                id: String(this.data?.ID),
                email: String(this.data?.Email),
                cookieValue: String(this.data?.CookieValue),
                fullName: String(this.data?.FullName),
                userType: toInt(this.data?.UserType),
                thumbnailImage: String(this.data?.ThumbnailImage),
                isEmailConfirmed: Boolean(this.data?.IsEmailConfirmed),
                fontSize: toInt(this.data?.FontSize),
                createdAt: String(this.data?.CreatedAt)
            })
        :
            new SignInEmpty();
    }
}