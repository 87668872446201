import React, { useState } from "react";

// getRoomMessage
import { Message } from "../fetch/messages/getRoom/entity";
import { QueryBuilder as GetRoomMessageQueryBuilder } from "../fetch/messages/getRoom/queryBuilder";
import { fetchGetRoomMessage } from "../fetch/messages/getRoom";
// create
import { QueryBuilder as CreateMessageQueryBuilder } from "../fetch/messages/create/queryBuilder";
import { fetchCreateMessage } from "../fetch/messages/create";
// createImage
import { QueryBuilder as CreateImageMessageQueryBuilder } from "../fetch/messages/createImage/queryBuilder";
import { fetchCreateImageMessage } from "../fetch/messages/createImage";
// createQuestion
import { QueryBuilder as CreateQuestionMessageQueryBuilder } from "../fetch/messages/createQuestion/queryBuilder";
import { fetchCreateQuestionMessage } from "../fetch/messages/createQuestion";
// update
import { QueryBuilder as UpdateMessageQueryBuilder } from "../fetch/messages/update/queryBuilder";
import { fetchUpdateMessage } from "../fetch/messages/update";

export const useMessage = (isLogin: boolean, isConfirmed: boolean) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [error, setError] = useState<string | null>(null);
    const getRoomMessage = async(roomID: string): Promise<boolean> => {
        if (!isLogin || !isConfirmed) return false;
        setIsLoading(true);
        const queryBuilder = new GetRoomMessageQueryBuilder();
        queryBuilder.setRoomID(roomID);
        const result = await fetchGetRoomMessage(queryBuilder.build());
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return false;
        }
        setIsLoading(false);
        setMessages(result.data);
        return true;
    }
    const createMessage = async(roomID: string, content: string): Promise<boolean> => {
        if (!isLogin || !isConfirmed) return false;
        setIsLoading(true);
        const queryBuilder = new CreateMessageQueryBuilder();
        queryBuilder.setRoomID(roomID);
        queryBuilder.setContent(content);
        const result = await fetchCreateMessage(queryBuilder.build());
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return false;
        }
        setIsLoading(false);
        // setMessages((prev) => {
        //     return [...prev, result.data]
        // });
        const queryBuilder2 = new GetRoomMessageQueryBuilder();
        queryBuilder2.setRoomID(roomID);
        const result2 = await fetchGetRoomMessage(queryBuilder2.build());
        setMessages(result2.data);
        return true;
    }

    const createImageMessage = async (roomID: string, content: string, imageUrl: string): Promise<boolean> => {
        if (!isLogin || !isConfirmed) return false;
        setIsLoading(true);
        const queryBuilder = new CreateImageMessageQueryBuilder();
        queryBuilder.setRoomID(roomID);
        queryBuilder.setImageUrl(imageUrl);
        queryBuilder.setContent(content);
        const result = await fetchCreateImageMessage(queryBuilder.build());
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return false;
        }
        setIsLoading(false);
        // setMessages((prev) => {
        //     return [...prev, result.data]
        // });
        const queryBuilder2 = new GetRoomMessageQueryBuilder();
        queryBuilder2.setRoomID(roomID);
        const result2 = await fetchGetRoomMessage(queryBuilder2.build());
        setMessages(result2.data);
        return true;
    }

    const createQuestionMessage = async(roomID: string, questionID: number, content: string): Promise<boolean> => {
        if (!isLogin || !isConfirmed) return false;
        setIsLoading(true);
        const queryBuilder = new CreateQuestionMessageQueryBuilder();
        queryBuilder.setRoomID(roomID);
        queryBuilder.setQuestionID(questionID);
        queryBuilder.setContent(content);
        const result = await fetchCreateQuestionMessage(queryBuilder.build());
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return false;
        }
        setIsLoading(false);
        // setMessages((prev) => {
        //     return [...prev, result.data]
        // });
        const queryBuilder2 = new GetRoomMessageQueryBuilder();
        queryBuilder2.setRoomID(roomID);
        const result2 = await fetchGetRoomMessage(queryBuilder2.build());
        setMessages(result2.data);
        return true;
    }

    const updateMessage = async (roomID: string, messageID: number, content: string) => {
        if (!isLogin || !isConfirmed) return false;
        setIsLoading(true);
        const queryBuilder = new UpdateMessageQueryBuilder();
        queryBuilder.setRoomID(roomID);
        queryBuilder.setMessageID(messageID);
        queryBuilder.setContent(content);
        const result = await fetchUpdateMessage(queryBuilder.build());
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return false;
        }
        setIsLoading(false);
        // setMessages((prev) => {
        //     return prev.map((message) => {
        //         if (message.id === result.data.id) return result.data;
        //         return message;
        //     })
        // });
        const queryBuilder2 = new GetRoomMessageQueryBuilder();
        queryBuilder2.setRoomID(roomID);
        const result2 = await fetchGetRoomMessage(queryBuilder2.build());
        setMessages(result2.data);
        return true;
    }

    return {
        messages, isLoading, error,
        getRoomMessage, createMessage, createImageMessage, createQuestionMessage, updateMessage
    }
}