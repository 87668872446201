export type Question = {
    id: number;
    title: string;
    content: string;
    typeID: number;
    typeTitle: string;
    typeUser: number;
    createdAt: string;
}

export class GetQuestionsEntity {
    data: Question[];
    error?: string;
    constructor(data: Question[], error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

export class GetQuestionsEmpty extends GetQuestionsEntity {
    constructor() {
        super([]);
    }
}

export class GetQuestionsError extends GetQuestionsEntity {
    constructor(errorMessage?: string) {
        super([], errorMessage);
    }
}