import AWS from "aws-sdk"
import { Button } from "@mui/material";
import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Textarea } from "@mui/joy";

import { Question } from "../../../fetch/questions/getAll/entity";
import { QuestionType } from "../../../fetch/questionTypes/getAll/entity";
import { User as CookieUser } from "../../../fetch/users/cookieUser/entity";
import { isPictureFile } from "../../../lib/helper/imegaHelper";

import QuestionFilter from "../QuestionFilter/component";

import "./style.css"

const bucketName = "65diary-image";
const bucketRegion = "us-west-1"
const identityPoolID = "us-west-1:0d55780b-9636-4114-bfcf-b22c1f6d88df";

type Props = {
    createMessage: (roomID: string, content: string) => Promise<boolean>;
    createImageMessage: (roomID: string, content: string, imageUrl: string) => Promise<boolean>;
    createQuestionMessage: (roomID: string, questionID: number, content: string) => Promise<boolean>;
    user: CookieUser;
    roomID: string;
    questions: Question[];
    questionTypes: QuestionType[];
}

type Input1Props = {
    roomID: string;
    onClickResetMessageType: () => void;
    createMessage: (roomID: string, content: string) => Promise<boolean>;
}

type Input2Props = {
    questions: Question[];
    questionTypes: QuestionType[];
    selectedQuestion: number;
    roomID: string;
    user: CookieUser;
    onClickResetMessageType: () => void;
    createQuestionMessage: (roomID: string, questionID: number, content: string) => Promise<boolean>;
}

type Input3Props = {
    roomID: string;
    onClickResetMessageType: () => void;
    createImageMessage: (roomID: string, content: string, imageUrl: string) => Promise<boolean>;
}

// 通常
const MessageInput1: React.FC<Input1Props> = ({
    roomID,
    onClickResetMessageType,
    createMessage
}) => {
    const [message, setMessage] = useState<string>("");
    const onClickMessage = async () => {
        if (!message) return;
        const result = await createMessage(roomID, message);
        if(result) onClickResetMessageType();
    }
    return (
        <>
            <div className="MessageInput__title">
                通常のメッセージを書く
            </div>
            <Textarea
                color="warning"
                placeholder="メッセージを入力してください"
                className="MessageInput__textarea"
                maxRows={10}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <div className="MessageInput__buttons">
                <Button
                    onClick={onClickMessage}
                    variant="contained"
                    color="success"
                >
                    メッセージを作成する
                </Button>
                <Button
                    onClick={onClickResetMessageType}
                    variant="contained"
                    color="error"
                >
                    キャンセルする
                </Button>
            </div>
        </>
    )
}

// 事業継承に関する知見
const MessageInput2: React.FC<Input2Props> = ({
    questions,
    questionTypes,
    selectedQuestion,
    roomID,
    user,
    onClickResetMessageType,
    createQuestionMessage,
}) => {
    const [message, setMessage] = useState<string>("");
    const currentQuestion = questions.find(q => q.id === selectedQuestion);
    const currentQuestionType = questionTypes.find((qt) => qt.id === currentQuestion?.typeID);
    const onClickQuestionMessage = async () => {
        if (!message || !currentQuestion) return;
        const result = await createQuestionMessage(roomID, currentQuestion.id,message);
        if(result) onClickResetMessageType();
    }
    return (
        <>
            <div className="MessageInput__title">
                {currentQuestion?.title || "事業継承に関する知見が選択されていません"}
                <span className="MessageInput__subTitle">{currentQuestion?.content.slice(0, 50) || ""}...</span>
                <br/>
                <small>{currentQuestionType?.title || "事業継承に関する知見が選択されていません"}</small>
            </div>
            <Textarea
                color="warning"
                placeholder="メッセージを入力してください"
                className="MessageInput__textarea"
                maxRows={10}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <div className="MessageInput__buttons">
                <Button
                    onClick={onClickQuestionMessage}
                    variant="contained"
                    color="success"
                >
                    メッセージを作成する
                </Button>
                <Button
                    onClick={onClickResetMessageType}
                    variant="contained"
                    color="error"
                >
                    キャンセルする
                </Button>
            </div>
        </>
    )
}

// 画像
const MessageInput3: React.FC<Input3Props> = ({
    roomID,
    onClickResetMessageType,
    createImageMessage
}) => {
    const [message, setMessage] = useState<string>("");
    const [imageUrl, setImageUrl] = useState<string>("");
    const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
    AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolID
        })
    });
    const s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: bucketName }
    });
    const onChangeThumbnailImageFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsImageLoading(true);
        const files = e.target.files;
       if (!files){
        setIsImageLoading(false);
        return;
       }
       const file = files[0];
       if (!isPictureFile(file.name)) {
        setIsImageLoading(false);
        return;
       }
       const randomNumber = Math.floor(Math.random() * 1000000);
       const photoKey = encodeURIComponent("images") + "/" + String(Date.now()) + String(randomNumber) + "_" + file.name;
       const upload = new AWS.S3.ManagedUpload({
        params: {
            Bucket: bucketName,
            Key: photoKey,
            Body: file,
        }
       });
       const promise = upload.promise();
       promise.then((_) => {
        const url = `https://65diary-image.s3.us-west-1.amazonaws.com/${photoKey}`
        console.log("success : ", url);
        setIsImageLoading(false);
        setImageUrl(url);
       }).catch((err) => {
        setIsImageLoading(false);
        console.log("error", err);
       })
    }
    const onClickImageMessage = async () => {
        if (!message || !imageUrl) return;
        const result = await createImageMessage(roomID, message, imageUrl);
        if(result) onClickResetMessageType();
    }
    return (
        <>
            <div className="MessageInput__title">
                画像のメッセージを書く
            </div>
            <Textarea
                color="warning"
                placeholder="メッセージを入力してください"
                className="MessageInput__textarea"
                maxRows={10}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <input className="MessageInput__textarea" type="file" onChange={onChangeThumbnailImageFile} />
            <div className="MessageInput__buttons">
                <Button
                    onClick={onClickImageMessage}
                    variant="contained"
                    color="success"
                    disabled={isImageLoading}
                >
                    画像つきメッセージを作成する
                </Button>
                <Button
                    onClick={onClickResetMessageType}
                    variant="contained"
                    color="error"
                >
                    キャンセルする
                </Button>
            </div>
        </>
    )
}

type SwitchProps = {
    messageType: number;
} & Input2Props & Input1Props & Input3Props

const MessageInputSwitch: React.FC<SwitchProps> = ({
    messageType,
    questions,
    questionTypes,
    roomID,
    user,
    selectedQuestion,
    onClickResetMessageType,
    createQuestionMessage,
    createMessage,
    createImageMessage
}) => {
    const inputComponent = () => {
        switch(messageType) {
            case 1:
                return(
                    <MessageInput1
                        roomID={roomID}
                        onClickResetMessageType={onClickResetMessageType}
                        createMessage={createMessage}
                    />
                )
            case 2:
                return(
                    <MessageInput2
                        questions={questions}
                        questionTypes={questionTypes}
                        selectedQuestion={selectedQuestion}
                        roomID={roomID}
                        user={user}
                        onClickResetMessageType={onClickResetMessageType}
                        createQuestionMessage={createQuestionMessage}
                    />
                )
            case 3:
                return(
                <MessageInput3
                    roomID={roomID}
                    onClickResetMessageType={onClickResetMessageType}
                    createImageMessage={createImageMessage}
                />
                )
            default:
                return null;
        }
    }
    return (<>{inputComponent()}</>)
}

const MessageInput: React.FC<Props> = ({
    questions,
    questionTypes,
    roomID,
    user,
    createMessage,
    createImageMessage,
    createQuestionMessage
}) => {
    const [messageType, setMessageType] = useState<number>(0);
    const [questionOpen, setQuestionOpen] = useState<boolean>(false);
    const [selectedQuestion, setSelectedQuestion] = useState<number>(0)
    const handleQuestionClose = (id: number) => {
        setQuestionOpen(false);
        setMessageType(id);
    }
    const onClickNormalType = () => {
        setMessageType(1);
    }
    const onClickQuestionType = () => {
        setQuestionOpen(true);
        setMessageType(2);
    }
    const onClickImageType = () => {
        setMessageType(3);
    }
    const onClickSelectQuestion = (id: number) => {
        setSelectedQuestion(id);
        setQuestionOpen(false);
    }
    const onClickResetMessageType = () => {
        setMessageType(0);
        const messenger = document.getElementsByClassName("Messenger")[0]
        setTimeout(() =>messenger.scroll(0, messenger.scrollHeight), 300);
    }
    return (
        <>
            <div className="MessageInput">
                {
                    messageType === 0
                    ?
                        <div className="MessageInput__main">
                            <Button onClick={onClickNormalType} size="small">普通のメッセージ</Button>
                            <Button onClick={onClickQuestionType} size="small">事業継承に関する知見</Button>
                            <Button onClick={onClickImageType} size="small">画像メッセージ</Button>
                        </div>
                    :
                        <MessageInputSwitch
                            questions={questions}
                            questionTypes={questionTypes}
                            selectedQuestion={selectedQuestion}
                            roomID={roomID}
                            user={user}
                            messageType={messageType}
                            onClickResetMessageType={onClickResetMessageType}
                            createQuestionMessage={createQuestionMessage}
                            createMessage={createMessage}
                            createImageMessage={createImageMessage}
                        />
                }
            </div>
            <Dialog
                open={questionOpen}
                onClose={() => handleQuestionClose(0)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"事業継承に関する知見を選んでください"}
                </DialogTitle>
                <DialogContent id="alert-dialog-description">
                    <QuestionFilter
                        questions={questions}
                        questionTypes={questionTypes}
                        onClickSelectQuestion={onClickSelectQuestion}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleQuestionClose(0)}>閉じる</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MessageInput;