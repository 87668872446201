import { GetRoomEntity, GetRoomEmpty } from "./entity";

export class ResponseMapper{
    private data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert(): GetRoomEntity{
        return this.data
        ? new GetRoomEntity({
            id: String(this.data?.ID),
            roomTitle: String(this.data?.RoomTitle),
            roomThumbnailImage: String(this.data?.RoomThumbnailImage),
            roomOwnerID: String(this.data?.RoomOwnerID),
            createdAt: String(this.data?.RoomCreateAt),
            ownerFullName: String(this.data?.OwnerFullName),
            ownerThumbnailImage: String(this.data?.OwnerThumbnailImage)
        })
        : new GetRoomEmpty();
    }
}