export type ConfirmStatus = {
    status: string;
}

export class ConfirmEmailEntity {
    data: ConfirmStatus;
    error?: string;
    constructor(data: ConfirmStatus, error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

const emptyStatus = {
    status: ""
};

export class ConfirmEmailEmpty extends ConfirmEmailEntity {
    constructor() {
        super(emptyStatus);
    }
}

export class ConfirmEmailError extends ConfirmEmailEntity {
    constructor(errorMessage: string) {
        super(emptyStatus, errorMessage);
    }
}