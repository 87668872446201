import { GetRoomMessageEntity, GetRoomMessageError } from "./entity";
import { ResponseMapper } from "./responseMapper";
import { fetchUrl } from "../../../lib/const/url";

export async function fetchGetRoomMessage(query: any): Promise<GetRoomMessageEntity> {
    try {
        const response = await fetch(`${fetchUrl}/messages/room`, {
            method: "POST",
            body: query,
            credentials: "include"
        }).then((data) => data.json());
        if (response.message) {
            return new GetRoomMessageError(response.message);
        }
        const entity = new ResponseMapper(response).convert();
        return entity;
    }catch (e) {
        console.log(e);
        return new GetRoomMessageError(JSON.stringify(e));
    }
}