import { SignOutUserEntity, SignOutUserEmpty } from "./entity";

export class ResponseMapper{
    data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert(): SignOutUserEntity {
        return this.data
        ? new SignOutUserEntity({
            status: String(this.data?.status)
        })
        : new SignOutUserEmpty();
    }
}