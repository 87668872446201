export type Csrf = {
    validator: string;
    userId: number;
}

export class CreateEntity {
    data: Csrf;
    error?: string;
    constructor(data: Csrf, error?: string) {
        this.data = data;
        if(error) this.error = error;
    }
}

export class CreateEmpty extends CreateEntity{
    constructor() {
        super({validator: "", userId: 0});
    }
}

export class CreateError extends CreateEntity {
    constructor(errorMessage: string) {
        super({validator: "", userId: 0}, errorMessage);
    }
}