export type Message = {
    id: number;
    userID: string;
    questionID: number;
    imageUrl: string;
    content: string;
    createdAt: string;
}

export class GetRoomMessageEntity {
    data: Message[];
    error?: string;
    constructor(data: Message[], error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

export class GetRoomMessageEmpty extends GetRoomMessageEntity{
    constructor(){
        super([]);
    }
}

export class GetRoomMessageError extends GetRoomMessageEntity {
    constructor(errorMessage: string) {
        super([], errorMessage);
    }
}