export type Room = {
    id: string;
    roomTitle: string;
    roomThumbnailImage: string;
    roomOwnerID: string;
    createdAt: string;
    ownerFullName: string;
    ownerThumbnailImage: string;
}

export class GetRoomEntity {
    data: Room;
    error?: string;
    constructor(data: Room, error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

const emptyRoom = {
    id: "",
    roomTitle: "",
    roomThumbnailImage: "",
    roomOwnerID: "",
    createdAt: "",
    ownerFullName: "",
    ownerThumbnailImage: ""
}

export class GetRoomEmpty extends GetRoomEntity {
    constructor() {
        super(emptyRoom)
    }
}

export class GetRoomError extends GetRoomEntity {
    constructor(errorMessage: string) {
        super(emptyRoom, errorMessage)
    }
}