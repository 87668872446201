import { UpdateMessageError, UpdateMessageEntity } from "./entity"
import { ResponseMapper } from "./responseMapper"
import { fetchUrl } from "../../../lib/const/url"

export async function fetchUpdateMessage(query: any): Promise<UpdateMessageEntity> {
    try {
        const response = await fetch(`${fetchUrl}/messages/update`, {
            method: "POST",
            body: query,
            credentials: "include"
        }).then((data) => data.json());
        if (response.message) {
            return new UpdateMessageError(response.message);
        }
        const entity = new ResponseMapper(response).convert();
        return entity;
    } catch (e) {
        console.log(e);
        return new UpdateMessageError(JSON.stringify(e))
    }
}