import React, { useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import Button from "@mui/material/Button";

import { fetchUrl } from "../../../lib/const/url";

import "./style.css";

type Props = {
    isConfirmed: boolean;
}

const ConfirmNeeded: React.FC<Props> = ({isConfirmed}) => {
    const [isResendSnackbarDisplayed, setIsResendSnackbarDisplayed] = useState<boolean>(false)
    const onClickResendConfirmEmail = async () => {
        const result = await fetch(`${fetchUrl}/confirm_users/resend_confirm_email`, {
            credentials: "include"
        }).then((data) => data.json());
        if (result.Status === "success") {
            setIsResendSnackbarDisplayed(true);
        }
    }
    return(
        <div className="ConfirmNeeded">
            <h3 className="ConfirmNeeded__title">
                ユーザー認証が必要です。
                <br/>
                メールアドレスにメールが届いているか確認してください。
            </h3>
            <Button
                onClick={onClickResendConfirmEmail}
                color="warning"
                variant="contained"
            >
                認証メールを送る
            </Button>
            <Snackbar
                open={isResendSnackbarDisplayed}
                autoHideDuration={3000}
                message="認証メールを送りました！"
            />
        </div>
    )
}

export default ConfirmNeeded;