export class QueryBuilder {
    private query: any;
    constructor() {
        this.query = {};
    }
    setRoomID(roomID: string) {
        this.query.roomID = roomID;
    }
    setContent(content: string) {
        this.query.content = content;
    }
    setImageUrl(imageUrl: string) {
        this.query.imageUrl = imageUrl;
    }
    build() {
        return JSON.stringify(this.query);
    }
}