export class QueryBuilder {
    private query: any;
    constructor() {
        this.query = {}
    }
    setMessageID(messageID: number) {
        this.query.messageID = messageID;
    }
    setRoomID(roomID: string) {
        this.query.roomID = roomID;
    }
    setPrompt(prompt: string) {
        this.query.prompt = prompt;
    }
    build() {
        return JSON.stringify(this.query);
    }
}