export class QueryBuilder{
    private query: any;
    constructor() {
        this.query = {};
    }
    setID(id: number) {
        this.query.ID = id;
    }
    setRoomID(roomID: string) {
        this.query.roomID = roomID;
    }
    build() {
        return JSON.stringify(this.query);
    }
}