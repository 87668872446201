import React, { useState, useEffect } from "react";

// get
import { fetchGetRoom } from "../fetch/rooms/get";
// getConfirmed
import { fetchConfirmedRoom } from "../fetch/rooms/getConfirmed";
import { Room } from "../fetch/rooms/getConfirmed/entity";
// create
import { fetchCreateRoom } from "../fetch/rooms/create";
import { QueryBuilder as CreateQueryBuilder } from "../fetch/rooms/create/queryBuilder";
// update
import { fetchUpdateRoom } from "../fetch/rooms/update";
import { QueryBuilder as UpdateQueryBuilder } from "../fetch/rooms/update/queryBuilder";

export const useRoom = (isLogin: boolean, isConfirmed: boolean) => {
    const [rooms, setRooms] = useState<Room[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [error, setError] = useState<string | null>(null);
    const getConfirmedRoom = async(): Promise<boolean> => {
        if (!isLogin || !isConfirmed) return false;
        setIsLoading(true);
        const result = await fetchConfirmedRoom();
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return false;
        }
        setIsLoading(false);
        setRooms(result.data);
        return true;
    }
    const createRoom = async(title: string, thumbnailImage: string, userIDs: string[], csrf: string): Promise<boolean> => {
        if (!isLogin || !isConfirmed) return false;
        setIsLoading(true);
        const queryBuilder = new CreateQueryBuilder();
        queryBuilder.setTitle(title);
        queryBuilder.setThumbnailImage(thumbnailImage);
        queryBuilder.setUserIDs(userIDs);
        queryBuilder.setCsrf(csrf);
        const result = await fetchCreateRoom(queryBuilder.build());
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return false;
        }
        setIsLoading(false);
        setRooms([...rooms, {
                ...result.data,
                createdAt: String(Date.now())
            }
        ]);
        return true;
    }
    const getRoom = async (roomID: string): Promise<Room | undefined> => {
        if (!isLogin || !isConfirmed) return undefined;
        setIsLoading(true);
        const result = await fetchGetRoom(roomID);
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return undefined;
        }
        setIsLoading(false);
        const isRoomExists = rooms.find((r) => r.id === result.data.id); // 念のため確認
        if (!isRoomExists) return undefined;
        const roomResult: Room = {
            id: result.data.id,
            title: result.data.roomTitle,
            thumbnailImage: result.data.roomThumbnailImage,
            ownerID: result.data.roomOwnerID,
            createdAt: result.data.createdAt
        }
        return roomResult;
    }
    const updateRoom = async(roomID: string, title: string, thumbnailImage: string, csrf: string): Promise<boolean> => {
        if (!isLogin || !isConfirmed) return false;
        setIsLoading(true);
        const queryBuilder = new UpdateQueryBuilder();
        queryBuilder.setTitle(title);
        queryBuilder.setThumbnailImage(thumbnailImage);
        queryBuilder.setCsrf(csrf);
        const result = await fetchUpdateRoom(roomID, queryBuilder.build());
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return false;
        }
        setRooms((prev) => {
            return prev.map((room) => {
                if (room.id === roomID) {
                    return {...room, ...result.data};
                }
                return room;
            })
        });
        setIsLoading(false);
        return true;
    }

    useEffect(() => {
        getConfirmedRoom();
    }, [isLogin, isConfirmed])

    return {
        rooms, isLoading, error,
        getConfirmedRoom, createRoom, getRoom, updateRoom
    }
}