import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Textarea } from "@mui/joy";
import { Button } from "@mui/material";
import classnames from "classnames";

import { User } from "../../../fetch/users/getRoomUser/entity";
import { Message as MessageType } from "../../../fetch/messages/getRoom/entity";

import "./style.css";
import { defaultUserUrl } from "../../../lib/const/url";
import { Question } from "../../../fetch/questions/getAll/entity";

type Props = {
    message: MessageType;
    questions: Question[];
    currentUserID: string;
    roomID: string;
    confirmedUsers: User[];
    updateMessage: (roomID: string, messageID: number, content: string) => Promise<boolean>;
}

const EachMessage: React.FC<Props> = ({
    roomID,
    message,
    questions,
    currentUserID,
    confirmedUsers,
    updateMessage
}) => {
    const isMe = message.userID === currentUserID;
    const isMessageBig = message.content.length > 255;
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editMessage, setEditMessage] = useState<string>(message.content);
    const currentUser = confirmedUsers.find((u) => u.id === message.userID);
    const currentQuestion = message.questionID
    ? questions.find((q) => q.id === message.questionID)
    : undefined
    const onClickEditComplete = () => {
        updateMessage(roomID, message.id, editMessage);
        setIsEditing(false);
    }
    return (
        <>
        <div className={classnames("EachMessage", isMe ? "EachMessage--me" : "EachMessage--you")}>
            { !isMe &&
            <div className="EachMessage__user">
                <img
                    src={currentUser?.thumbnailImage || defaultUserUrl}
                    width="20"
                    height="20"
                    alt={currentUser?.fullName}
                />
                {currentUser?.fullName}
            </div>
            }
            <div className={classnames("EachMessage__inner", isMe ? "EachMessage__inner--me" : "EachMessage__inner--you")}>
                { currentQuestion &&
                    <div className="EachMessage__innerQuestionTitle">{currentQuestion.title}に関するメッセージ</div>
                }
                {isEditing && isMe
                    ?
                        <>
                            <Textarea
                                value={editMessage}
                                className="EachMessage__editForm"
                                onChange={(e) => setEditMessage(e.target.value)}
                                maxRows={5}
                            />
                            <Button color="success" size="small" variant="contained" onClick={() => onClickEditComplete()}>
                                修正する
                            </Button>
                            <Button color="primary" size="small" variant="contained" onClick={() => setIsEditing(false)}>
                                キャンセル
                            </Button>
                        </>
                    :
                    isMessageBig
                    ?
                        <>
                            {message.content.slice(0, 256).split("\n").map((line, index) => {
                                return <React.Fragment key={index}>{line}<br/></React.Fragment>
                            })}...
                            { !message.questionID &&
                                <Link className="EachMessage__link" to={`/messages/${message.id}`}>
                                    続きを見る
                                </Link>
                            }
                        </>
                    :
                    <>
                        {message.content.split("\n").map((line, index) => {
                            return <React.Fragment key={index}>{line}<br/></React.Fragment>
                        })}
                    </>
                }
                { !!message.questionID &&
                    <Link className="EachMessage__link" to={`/messages/${message.id}`}>
                        詳しく見る
                    </Link>
                }
                { !isEditing && isMe &&
                <>
                    <Button color="error" variant="contained" size="small" onClick={() => setIsEditing(true)}>
                        編集する
                    </Button>
                </>
                }
            </div>
            { isMe &&
                <div className="EachMessage__user--right">
                    <img
                        src={currentUser?.thumbnailImage || defaultUserUrl}
                        width="20"
                        height="20"
                        alt={currentUser?.fullName}
                    />
                    {currentUser?.fullName}
                </div>
            }
        </div>
        {message.imageUrl &&
            <div style={{width: "calc(100% - 140px)", display: "flex", justifyContent: isMe ? "flex-end" : "flex-start", margin: "0 70px"}}>
                <a href={message.imageUrl} target="_blank" rel="noreferrer">
                    <img src={message.imageUrl} width="100" alt={message.content}/>
                </a>
            </div>
        }
        </>
    )
}

export default EachMessage;