export class QueryBuilder {
    private query: any;
    constructor() {
        this.query = {};
    }
    setFullName(fullName: string) {
        this.query.fullName = fullName;
    }
    setThumbnailImage(thumbnailImage: string) {
        this.query.thumbnailImage = thumbnailImage;
    }
    setFontSize(fontSize: number) {
        this.query.fontSize = fontSize;
    }
    setCsrf(csrf: string) {
        this.query.csrf = csrf;
    }
    build() {
        return JSON.stringify(this.query);
    }
}