import React, { useEffect } from "react";
import Header from "../../organisms/Header/component";

import "./style.css";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
}

const QuestionAll: React.FC<Props> = ({
    isLogin,
    isAuthLoading
}) => {
    useEffect(() => {
        window.scroll(0, 0);
    }, [])
    return(
        <div className="QuestionsAll">
            <Header isLogin={isLogin} isAuthLoading={isAuthLoading}/>
            <div className="QuestionsAll__main">
                <h1>事業継承に関する知見一覧</h1>
                <hr color="warning"/>
                <h3>基本情報</h3>
                <div className="QuestionsAll__question">
                <p>経営者の１日</p>
                <small className="QuestionsAll__small">経営戦略の決定,重要顧客との面会,財務状況の確認,従業員との面会,業界動向の把握</small>
                </div>
                <div className="QuestionsAll__question">
                <p>採用</p>
                <small className="QuestionsAll__small">採用プロセスの透明性,採用基準の整合性,募集チャネルの多様性,採用コスト分析,内定後の定着率</small>
                </div>
                <div className="QuestionsAll__question">
                <p>社員の雇用状況</p>
                <small className="QuestionsAll__small">社員数の確認,パートタイマーの確認,派遣社員の確認,退職率の調査,テレワーク導入状況</small>
                </div>
                <div className="QuestionsAll__question">
                <p>組織体制の分析</p>
                <small className="QuestionsAll__small">組織図の確認,役員のポジション,役員の業績評価,組織文化の把握,組織の風土調査</small>
                </div>
                <div className="QuestionsAll__question">
                <p>社員のスキル分析</p>
                <small className="QuestionsAll__small">社員の技術力,社員の知識レベル,社員の語学力,社員のマネジメント力,社員の専門性</small>
                </div>
                <div className="QuestionsAll__question">
                <p>製品ラインアップ</p>
                <small className="QuestionsAll__small">製品種類,製品特徴,新製品開発状況,製品ライフサイクル,販売製品数</small>
                </div>
                <div className="QuestionsAll__question">
                <p>製品の生産</p>
                <small className="QuestionsAll__small">生産ライン状況,生産能力,生産効率,原材料調達状況,生産コスト</small>
                </div>
                <div className="QuestionsAll__question">
                <p>製品の販売</p>
                <small className="QuestionsAll__small">販売戦略,販売実績,販売チャンネル,販売マージン,販売先の多様性</small>
                </div>
                <div className="QuestionsAll__question">
                <p>市場環境</p>
                <small className="QuestionsAll__small">市場規模,市場シェア,市場競争状況,市場の成長性,市場ニーズ</small>
                </div>
                <div className="QuestionsAll__question">
                <p>技術状況</p>
                <small className="QuestionsAll__small">技術革新,技術差別化,技術開発力,技術ライセンス,製品開発状況</small>
                </div>
                <div className="QuestionsAll__question">
                <p>製品価格</p>
                <small className="QuestionsAll__small">製品単価,価格競争力,価格設定方法,販売価格動向,価格変動影響</small>
                </div>
                <div className="QuestionsAll__question">
                <p>顧客関係</p>
                <small className="QuestionsAll__small">顧客需要動向,顧客満足度,重要顧客,顧客のバイイン,顧客ロイヤルティ</small>
                </div>
                <div className="QuestionsAll__question">
                <p>会社概要</p>
                <small className="QuestionsAll__small">設立年月,資本金,売上高,従業員数,事業内容</small>
                </div>
                <div className="QuestionsAll__question">
                <p>金融情報</p>
                <small className="QuestionsAll__small">貸借対照表,損益計算書,現金流量計算書,資本性資産,有形無形資産</small>
                </div>
                <div className="QuestionsAll__question">
                <p>経営層</p>
                <small className="QuestionsAll__small">経営陣構成,経歴・スキル,役員報酬,経営の透明性,後継者問題</small>
                </div>
                <div className="QuestionsAll__question">
                <p>事業戦略</p>
                <small className="QuestionsAll__small">競合優位性,事業計画,収益性,拡大可能性,事業リスク</small>
                </div>
                <div className="QuestionsAll__question">
                <p>PEST分析（外部環境分析）</p>
                <small className="QuestionsAll__small">Politics（政治的要因）,Economics（経済的要因）,Social（社会的要因）,Technology（技術的要因）</small>
                </div>
                <div className="QuestionsAll__question">
                <p>5フォース分析（外部環境分析）</p>
                <small className="QuestionsAll__small">Entry（新規参入）,Rivalry（競合）,Substitutes（代替品）,Suppliers（供給者）,Buyers（購入者）</small>
                </div>
                <div className="QuestionsAll__question">
                <p>VRIOフレームワーク（内部環境分析）</p>
                <small className="QuestionsAll__small">Value（経済価値）,Rarity（希少性）,Inimitability（模倣困難性）,Organization（組織）</small>
                </div>
                <div className="QuestionsAll__question">
                <p>バリューチェーン分析（内部環境分析）</p>
                <small className="QuestionsAll__small">業務を洗い出す,コスト情報を整理する,強みと弱みを分析する</small>
                </div>
                <div className="QuestionsAll__question">
                <p>税制優遇措置</p>
                <small className="QuestionsAll__small">R&D支援制度,雇用創出税制,地域振興税制,中小企業振興税制,環境配慮税制</small>
                </div>
                <div className="QuestionsAll__question">
                <p>IT戦略</p>
                <small className="QuestionsAll__small">ITのビジネス貢献,IT戦略の明確性,新技術導入計画,予算計画,ビジネス連携状況</small>
                </div>
                <div className="QuestionsAll__question">
                <p>業務アプリケーション</p>
                <small className="QuestionsAll__small">必要な機能,ユーザー満足度,データ整合性,システム寿命予想,買替え計画</small>
                </div>
                <br/>
                <h4>
                    以下は、M&Aのデューデリジェンスで必要な情報で、100項目程度あります。
                    <br/>
                    もし全て埋めるのが手間でしたら、一旦「<a href="#improvementTitle">改善</a>」まで飛ばしてもらっても構いません。</h4>
                <hr/>
                <h3>人事</h3>
                <div className="QuestionsAll__question">
                <p>採用</p>
                <small className="QuestionsAll__small">採用プロセスの透明性,採用基準の整合性,募集チャネルの多様性,採用コスト分析,内定後の定着率</small>
                </div>
                <div className="QuestionsAll__question">
                <p>社員の雇用状況</p>
                <small className="QuestionsAll__small">社員数の確認,パートタイマーの確認,派遣社員の確認,退職率の調査,テレワーク導入状況</small>
                </div>
                <div className="QuestionsAll__question">
                <p>組織体制の分析</p>
                <small className="QuestionsAll__small">組織図の確認,役員のポジション,役員の業績評価,組織文化の把握,組織の風土調査</small>
                </div>
                <div className="QuestionsAll__question">
                <p>人事制度の評価</p>
                <small className="QuestionsAll__small">評価制度の確認,給与制度の確認,教育制度の調査,福利厚生の調査,昇進制度の確認</small>
                </div>
                <div className="QuestionsAll__question">
                <p>社員のスキル分析</p>
                <small className="QuestionsAll__small">社員の技術力,社員の知識レベル,社員の語学力,社員のマネジメント力,社員の専門性</small>
                </div>
                <div className="QuestionsAll__question">
                <p>人事戦略の検証</p>
                <small className="QuestionsAll__small">負債の確認,将来展望の分析,経営者のビジョン,事業計画の妥当性確認,成功の可能性分析</small>
                </div>
                <div className="QuestionsAll__question">
                <p>労働時間管理</p>
                <small className="QuestionsAll__small">残業時間の調査,労働時間の記録,労働時間に関するルール,休憩時間の確認,有休取得状況</small>
                </div>
                <div className="QuestionsAll__question">
                <p>労使関係の調査</p>
                <small className="QuestionsAll__small">労働組合の存在,労働組合との関係,労働協議会の運営,従業員満足度,従業員のモチベーション</small>
                </div>
                <h3>製品</h3>
                <div className="QuestionsAll__question">
                <p>製品ラインアップ</p>
                <small className="QuestionsAll__small">製品種類,製品特徴,新製品開発状況,製品ライフサイクル,販売製品数</small>
                </div>
                <div className="QuestionsAll__question">
                <p>製品品質</p>
                <small className="QuestionsAll__small">製品の安全性,製品の耐久性,製品の信頼性,顧客評価,製品リコール状況</small>
                </div>
                <div className="QuestionsAll__question">
                <p>知的財産</p>
                <small className="QuestionsAll__small">特許取得状況,特許対抗可能性,商標登録状況,著作権所有状況,ノウハウ保有状況</small>
                </div>
                <div className="QuestionsAll__question">
                <p>製品の生産</p>
                <small className="QuestionsAll__small">生産ライン状況,生産能力,生産効率,原材料調達状況,生産コスト</small>
                </div>
                <div className="QuestionsAll__question">
                <p>製品の販売</p>
                <small className="QuestionsAll__small">販売戦略,販売実績,販売チャンネル,販売マージン,販売先の多様性</small>
                </div>
                <div className="QuestionsAll__question">
                <p>規制と法律</p>
                <small className="QuestionsAll__small">適用法律,法令遵守状況,関連訴訟状況,製品規制,ライセンス取得状況</small>
                </div>
                <div className="QuestionsAll__question">
                <p>市場環境</p>
                <small className="QuestionsAll__small">市場規模,市場シェア,市場競争状況,市場の成長性,市場ニーズ</small>
                </div>
                <div className="QuestionsAll__question">
                <p>技術状況</p>
                <small className="QuestionsAll__small">技術革新,技術差別化,技術開発力,技術ライセンス,製品開発状況</small>
                </div>
                <div className="QuestionsAll__question">
                <p>製品価格</p>
                <small className="QuestionsAll__small">製品単価,価格競争力,価格設定方法,販売価格動向,価格変動影響</small>
                </div>
                <div className="QuestionsAll__question">
                <p>顧客関係</p>
                <small className="QuestionsAll__small">顧客需要動向,顧客満足度,重要顧客,顧客のバイイン,顧客ロイヤルティ</small>
                </div>
                <h3>ビジネス</h3>
                <div className="QuestionsAll__question">
                <p>会社概要</p>
                <small className="QuestionsAll__small">設立年月,資本金,売上高,従業員数,事業内容</small>
                </div>
                <div className="QuestionsAll__question">
                <p>金融情報</p>
                <small className="QuestionsAll__small">貸借対照表,損益計算書,現金流量計算書,資本性資産,有形無形資産</small>
                </div>
                <div className="QuestionsAll__question">
                <p>経営層</p>
                <small className="QuestionsAll__small">経営陣構成,経歴・スキル,役員報酬,経営の透明性,後継者問題</small>
                </div>
                <div className="QuestionsAll__question">
                <p>事業戦略</p>
                <small className="QuestionsAll__small">競合優位性,事業計画,収益性,拡大可能性,事業リスク</small>
                </div>
                <div className="QuestionsAll__question">
                <p>市場状況</p>
                <small className="QuestionsAll__small">市場規模,市場成長性,競合状況,市場ニーズ,顧客動向</small>
                </div>
                <div className="QuestionsAll__question">
                <p>営業・販売</p>
                <small className="QuestionsAll__small">販売チャネル,販売戦略,顧客とのリレーション,販売組織,販売実績</small>
                </div>
                <div className="QuestionsAll__question">
                <p>PEST分析（外部環境分析）</p>
                <small className="QuestionsAll__small">Politics（政治的要因）,Economics（経済的要因）,Social（社会的要因）,Technology（技術的要因）</small>
                </div>
                <div className="QuestionsAll__question">
                <p>5フォース分析（外部環境分析）</p>
                <small className="QuestionsAll__small">Entry（新規参入）,Rivalry（競合）,Substitutes（代替品）,Suppliers（供給者）,Buyers（購入者）</small>
                </div>
                <div className="QuestionsAll__question">
                <p>VRIOフレームワーク（内部環境分析）</p>
                <small className="QuestionsAll__small">Value（経済価値）,Rarity（希少性）,Inimitability（模倣困難性）,Organization（組織）</small>
                </div>
                <div className="QuestionsAll__question">
                <p>バリューチェーン分析（内部環境分析）</p>
                <small className="QuestionsAll__small">業務を洗い出す,コスト情報を整理する,強みと弱みを分析する</small>
                </div>
                <h3>財務</h3>
                <div className="QuestionsAll__question">
                <p>財務状況</p>
                <small className="QuestionsAll__small">売上高,営業利益,純利益,総資産,負債総額</small>
                </div>
                <div className="QuestionsAll__question">
                <p>収益性</p>
                <small className="QuestionsAll__small">ROA（総資産利益率）,ROE（自己資本利益率）,ROS（売上高利益率）,営業マージン,純利益マージン</small>
                </div>
                <div className="QuestionsAll__question">
                <p>安全性</p>
                <small className="QuestionsAll__small">自己資本比率,借入金比率,流動比率,流動資産総額,負債総額</small>
                </div>
                <div className="QuestionsAll__question">
                <p>効率性</p>
                <small className="QuestionsAll__small">総資産回転率,売上高回転率,収益回転周期,在庫回転率,資本回転率</small>
                </div>
                <div className="QuestionsAll__question">
                <p>成長性</p>
                <small className="QuestionsAll__small">売上高成長率,利益成長率,資産成長率,経常利益成長率,純利益成長率</small>
                </div>
                <div className="QuestionsAll__question">
                <p>財務比率</p>
                <small className="QuestionsAll__small">EPS（一株当たり利益）,PBR（時価純資産倍率）,PER（株価収益率）,配当利回り,自己資本比率</small>
                </div>
                <div className="QuestionsAll__question">
                <p>キャッシュフロー</p>
                <small className="QuestionsAll__small">営業キャッシュフロー,財務キャッシュフロー,投資キャッシュフロー,フリーキャッシュフロー,キャッシュフロー倍率</small>
                </div>
                <div className="QuestionsAll__question">
                <p>現金管理</p>
                <small className="QuestionsAll__small">現金残高,現金一日あたりの支出,現金と短期投資,現金収入時間,現金支払時間</small>
                </div>
                <div className="QuestionsAll__question">
                <p>投資効率</p>
                <small className="QuestionsAll__small">投資回収期間,投資利益率,投資リスク,投資可能期間,投資金額に対する利益</small>
                </div>
                <div className="QuestionsAll__question">
                <p>借入金管理</p>
                <small className="QuestionsAll__small">借入金総額,借入金利回り,借入金に対する利益,借入金リスク,借入金の返済期間</small>
                </div>
                <h3>税務</h3>
                <div className="QuestionsAll__question">
                <p>税務報告書の確認</p>
                <small className="QuestionsAll__small">有無の確認,数年分の確認,税込み収入の確認,未納税の確認,課税資産の確認</small>
                </div>
                <div className="QuestionsAll__question">
                <p>月次/年次決算</p>
                <small className="QuestionsAll__small">報告期間の確認,利益率の検証,損失傾向の確認,決算修正の確認,会計原則の遵守</small>
                </div>
                <div className="QuestionsAll__question">
                <p>消費税問題</p>
                <small className="QuestionsAll__small">未納の確認,課税事業の確認,免税事業の確認,還付税の確認,推定課税の把握</small>
                </div>
                <div className="QuestionsAll__question">
                <p>所得税問題</p>
                <small className="QuestionsAll__small">税率の適用誤り,経費計上誤り,減価償却誤り,課税所得誤り,申告漏れの確認</small>
                </div>
                <div className="QuestionsAll__question">
                <p>企業税</p>
                <small className="QuestionsAll__small">企業税負担確認,特別地方税確認,事業税率の確認,軽減税率の適用,過去の未納税確認</small>
                </div>
                <div className="QuestionsAll__question">
                <p>資産税</p>
                <small className="QuestionsAll__small">固定資産税の確認,軽自動車税の確認,都市計画税の確認,地方交付税の確認,特例税制の確認</small>
                </div>
                <div className="QuestionsAll__question">
                <p>税制優遇措置</p>
                <small className="QuestionsAll__small">R&D支援制度,雇用創出税制,地域振興税制,中小企業振興税制,環境配慮税制</small>
                </div>
                <div className="QuestionsAll__question">
                <p>税務状況の記録</p>
                <small className="QuestionsAll__small">納税記録の保存,記録の整合性確認,税務申告の正確性,税金滞納の記録,税金還付の記録</small>
                </div>
                <h3>法務</h3>
                <div className="QuestionsAll__question">
                <p>資本政策に関する事項</p>
                <small className="QuestionsAll__small">株主名簿,株主総会議事録,増資履歴,減資履歴,株式の譲渡制限</small>
                </div>
                <div className="QuestionsAll__question">
                <p>業務内容に関する事項</p>
                <small className="QuestionsAll__small">事業内容,商標,特許,業許可,業務契約</small>
                </div>
                <div className="QuestionsAll__question">
                <p>従業員に関する事項</p>
                <small className="QuestionsAll__small">従業員名簿,雇用契約書,労働条件,労働組合,従業員育成</small>
                </div>
                <div className="QuestionsAll__question">
                <p>契約書に関する事項</p>
                <small className="QuestionsAll__small">主要契約書,重要な覚書,解約条件,違約条項,秘密保持契約</small>
                </div>
                <div className="QuestionsAll__question">
                <p>財務報告に関する事項</p>
                <small className="QuestionsAll__small">決算書,監査報告書,資産評価,リスク管理,財務リスク</small>
                </div>
                <div className="QuestionsAll__question">
                <p>不動産に関する事項</p>
                <small className="QuestionsAll__small">所有不動産,賃借契約,建築許可,不動産評価,不動産使用許可</small>
                </div>
                <div className="QuestionsAll__question">
                <p>訴訟に関する事項</p>
                <small className="QuestionsAll__small">争訟中の訴訟,解決済み訴訟,訴訟リスク,裁定書,和解契約</small>
                </div>
                <div className="QuestionsAll__question">
                <p>株主に関する事項</p>
                <small className="QuestionsAll__small">大株主,関連会社,出資比率,取締役,上場/非上場</small>
                </div>
                <div className="QuestionsAll__question">
                <p>適法性に関する事項</p>
                <small className="QuestionsAll__small">法遵守,業法違反の有無,資格取得,行政処分,法律問題</small>
                </div>
                <h3>労務</h3>
                <div className="QuestionsAll__question">
                <p>労働条件</p>
                <small className="QuestionsAll__small">労働時間,給与体系,休日休暇,待遇改善,労働契約内容</small>
                </div>
                <div className="QuestionsAll__question">
                <p>雇用状況</p>
                <small className="QuestionsAll__small">正社員数,パート・アルバイト数,雇用形態,雇用契約更新状況,退職率</small>
                </div>
                <div className="QuestionsAll__question">
                <p>労働問題</p>
                <small className="QuestionsAll__small">労働争議発生状況,過去の労働問題,労働者の不満,残業問題,セクハラ問題</small>
                </div>
                <div className="QuestionsAll__question">
                <p>人事制度</p>
                <small className="QuestionsAll__small">昇給・昇進システム,人事評価制度,退職金制度,社員教育制度,福利厚生制度</small>
                </div>
                <div className="QuestionsAll__question">
                <p>健康・安全</p>
                <small className="QuestionsAll__small">安全基準の遵守,労働安全衛生法適用,過去の事故報告,健康診断実施状況,ストレスチェック</small>
                </div>
                <div className="QuestionsAll__question">
                <p>社会保険</p>
                <small className="QuestionsAll__small">社保適用状況,労災保険加入状況,保険料未納情報,退職金積立金,年金積立金</small>
                </div>
                <div className="QuestionsAll__question">
                <p>労働組合</p>
                <small className="QuestionsAll__small">労働組合有無,労組との関係,労組主導の行動,労組との労働協約,ストライキ発生履歴</small>
                </div>
                <div className="QuestionsAll__question">
                <p>従業員情報</p>
                <small className="QuestionsAll__small">従業員のスキル,従業員の経験年数,人材の流動性,重要な人材,人材の流出リスク</small>
                </div>
                <div className="QuestionsAll__question">
                <p>人員コスト</p>
                <small className="QuestionsAll__small">年間人件費,福利厚生費用,企業としての評価,解雇に伴う費用,退職金支払い予定額</small>
                </div>
                <div className="QuestionsAll__question">
                <p>法令遵守</p>
                <small className="QuestionsAll__small">労働法の遵守,違法労働の有無,パワハラ問題対策,法令違反の罰則,法令改正に伴う影響</small>
                </div>
                <h3>IT</h3>
                <div className="QuestionsAll__question">
                <p>システムインフラ</p>
                <small className="QuestionsAll__small">ハードウェアの状況,ソフトウェアのライセンス,保守・サポート体制,バックアップ体制の有無,ディザスタリカバリ計画</small>
                </div>
                <div className="QuestionsAll__question">
                <p>データセキュリティ</p>
                <small className="QuestionsAll__small">セキュリティポリシー,アクセス制御体制,外部との接続状態,機密情報管理体制,セキュリティ教育状況</small>
                </div>
                <div className="QuestionsAll__question">
                <p>ソフトウェアアセット</p>
                <small className="QuestionsAll__small">必要なソフトウェア,ライセンス契約状況,ソフトウェアのバージョン,定期的なアップデート,開発ツールの状況</small>
                </div>
                <div className="QuestionsAll__question">
                <p>IT人材</p>
                <small className="QuestionsAll__small">IT部門の人材構成,スキルセット,教育・研修制度,採用計画,人材流動性</small>
                </div>
                <div className="QuestionsAll__question">
                <p>システム運用</p>
                <small className="QuestionsAll__small">運用マニュアル,監視体制,定期的なレビュー,改善計画,運用コスト予測</small>
                </div>
                <div className="QuestionsAll__question">
                <p>IT戦略</p>
                <small className="QuestionsAll__small">ITのビジネス貢献,IT戦略の明確性,新技術導入計画,予算計画,ビジネス連携状況</small>
                </div>
                <div className="QuestionsAll__question">
                <p>ITコスト</p>
                <small className="QuestionsAll__small">IT予算の明細,隠れたITコスト,大型導入の影響予測,IT投資の回収時期,コスト管理体制</small>
                </div>
                <div className="QuestionsAll__question">
                <p>プロジェクト管理</p>
                <small className="QuestionsAll__small">プロジェクト体制,マネジメント手法,リスク管理,進捗管理,品質評価方法</small>
                </div>
                <div className="QuestionsAll__question">
                <p>業務アプリケーション</p>
                <small className="QuestionsAll__small">必要な機能,ユーザー満足度,データ整合性,システム寿命予想,買替え計画</small>
                </div>
                <div className="QuestionsAll__question">
                <p>ビジネス・プロセス</p>
                <small className="QuestionsAll__small">業務フロー整理,IT支援の可否,デジタル化の進行度,業務改善の可能性,業務知識の蓄積</small>
                </div>
                <div className="QuestionsAll__question">
                <p>特許権</p>
                <small className="QuestionsAll__small">特許数の確認,特許の内容理解,特許有効性確認,特許侵害リスク調査,特許更新手続き確認</small>
                </div>
                <h3>技術</h3>
                <div className="QuestionsAll__question">
                <p>技術レベル</p>
                <small className="QuestionsAll__small">技術力分析,技術適用範囲分析,技術開発体制評価,ノウハウ習得可能性評価,新技術開発可能性評価</small>
                </div>
                <div className="QuestionsAll__question">
                <p>技術ライセンス</p>
                <small className="QuestionsAll__small">ライセンス契約書確認,契約内容の詳細解析,契約満了期日確認,リネゴシエーション可能性確認,ライセンス報酬支払状況確認</small>
                </div>
                <div className="QuestionsAll__question">
                <p>研究開発</p>
                <small className="QuestionsAll__small">研究状況の評価,新製品開発進捗確認,予算配分の評価,研究施設状況確認,研究者スキル評価</small>
                </div>
                <div className="QuestionsAll__question">
                <p>製造技術</p>
                <small className="QuestionsAll__small">製造ライン整備状況,品質管理体制評価,製造コスト調査,製造リードタイム分析,効率化余地調査</small>
                </div>
                <div className="QuestionsAll__question">
                <p>技術規制</p>
                <small className="QuestionsAll__small">法令順守状況確認,環境規制対応確認,特許法遵守状況調査,プライバシー法遵守調査,産業規制対応調査</small>
                </div>
                <div className="QuestionsAll__question">
                <p>ITシステム</p>
                <small className="QuestionsAll__small">システム稼働状況評価,ITインフラ製品状態調査,ITセキュリティ評価,データ管理手法評価,システム更新計画調査</small>
                </div>
                <div className="QuestionsAll__question">
                <p>人員配置</p>
                <small className="QuestionsAll__small">技術者配置評価,スキルマッチング調査,採用計画審査,労務管理状況確認,人員流動性評価</small>
                </div>
                <div className="QuestionsAll__question">
                <p>技術提携</p>
                <small className="QuestionsAll__small">提携企業捜索,提携契約書確認,提携効果分析,提携リスク調査,提携更新手続き確認</small>
                </div>
                <div className="QuestionsAll__question">
                <p>技術戦略</p>
                <small className="QuestionsAll__small">技術戦略確認,戦略実効性分析,戦略リスク評価,戦略適応性評価,戦略変更可能性調査</small>
                </div>
                <h3>顧客</h3>
                <div className="QuestionsAll__question">
                <p>顧客の属性</p>
                <small className="QuestionsAll__small">顧客の人数,顧客の業種,顧客の地域,顧客の規模,顧客の信用度</small>
                </div>
                <div className="QuestionsAll__question">
                <p>顧客との関係</p>
                <small className="QuestionsAll__small">取引開始時期,取引頻度,取引金額,取引条件,取引の安定性</small>
                </div>
                <div className="QuestionsAll__question">
                <p>顧客の信用情報</p>
                <small className="QuestionsAll__small">信用調査結果,過去の取引履歴,支払い遅延情報,破産・再生情報,信用格付け</small>
                </div>
                <div className="QuestionsAll__question">
                <p>顧客満足度</p>
                <small className="QuestionsAll__small">顧客満足度調査,クレーム情報,満足度評価基準,満足度改善計画,顧客の声</small>
                </div>
                <div className="QuestionsAll__question">
                <p>顧客忠誠度</p>
                <small className="QuestionsAll__small">再購入率,紹介状況,アフターケアの評判,ブランドロイヤリティ,顧客ロックイン状況</small>
                </div>
                <div className="QuestionsAll__question">
                <p>顧客戦略</p>
                <small className="QuestionsAll__small">新規顧客獲得戦略,既存顧客育成戦略,顧客接点戦略,顧客体験戦略,顧客セグメンテーション</small>
                </div>
                <div className="QuestionsAll__question">
                <p>顧客データ管理</p>
                <small className="QuestionsAll__small">顧客データベース,データ管理体制,データセキュリティ,データ利用規約,個人情報保護政策</small>
                </div>
                <div className="QuestionsAll__question">
                <p>顧客パートナーシップ</p>
                <small className="QuestionsAll__small">パートナーシップ状況,パートナーシップ戦略,パートナー企業,パートナー評価,パートナーシップ契約</small>
                </div>
                <div className="QuestionsAll__question">
                <p>顧客チャネル</p>
                <small className="QuestionsAll__small">販売チャネル,マーケティングチャネル,コミュニケーションチャネル,チャネル管理,チャネル戦略</small>
                </div>
                <div className="QuestionsAll__question">
                <p>顧客規模と成長性</p>
                <small className="QuestionsAll__small">現在の規模,成長率,市場規模,市場シェア,成長性評価</small>
                </div>
                <br/>
                <h4 id="improvementTitle">以下は、新しい経営陣が入るときに参考になる「改善案」の項目です</h4>
                <hr/>
                <h3>株式・改善</h3>
                <div className="QuestionsAll__question">
                <p>株主名簿の整理</p>
                <small className="QuestionsAll__small">株主名簿の重要性,株主名簿の作成及び管理方法,適切な株主変更の手続き,株主名簿整理によるリスク管理,株主名簿整理時の法令遵守</small>
                </div>
                <div className="QuestionsAll__question">
                <p>株主は適法に株式を取得しているか</p>
                <small className="QuestionsAll__small">株式取得の過程と法律遵守,資本金等の納付に関する規定,取得株式の所有権証明,取引先との契約に関する合法性,異議申し立てとその対応</small>
                </div>
                <div className="QuestionsAll__question">
                <p>名義株の有無、書換え</p>
                <small className="QuestionsAll__small">名義株の存在確認,名義株主の身元確認,株主名簿の正確性,株式書き換え手 続,名義株主との合意</small>
                </div>
                <div className="QuestionsAll__question">
                <p>株式が分散している場合は、できるだけ株式を集める</p>
                <small className="QuestionsAll__small">株式分散の問題点とリスク,株式の集約方法と手段,事業の成長や存続に対する影響,株式集約の際の法的・税務的な考慮点,株式集約後の企業経営の効果と影響</small>
                </div>
                <div className="QuestionsAll__question">
                <p>定款の改定（法令との適合性）</p>
                <small className="QuestionsAll__small">現行の法令確認,定款の内容確認と照らし合わせ,法令違反部分の修正,定款改定の手続き,改定後の法令準拠チェック</small>
                </div>
                <h3>経営管理・改善</h3>
                <div className="QuestionsAll__question">
                <p>社内規程・就業規則の整備</p>
                <small className="QuestionsAll__small">法規制の確認と理解, 労働基準法への準拠, 給与体系や就業時間の明確化, 社員の権利義務の明示, 規定違反時の対応方針設定</small>
                </div>
                <div className="QuestionsAll__question">
                <p>許認可の届出、承継要件の確認</p>
                <small className="QuestionsAll__small">許認可の確認と手続き,法定承継の手続き,事業承継契約の要件,事業承継費用と税務,管理体制と事業継続の確保</small>
                </div>
                <div className="QuestionsAll__question">
                <p>株主総会や取締役会の護事録の整備</p>
                <small className="QuestionsAll__small">議事録の基本的な作成方法,株主総会・取締役会議事録の重要性,法令に基づく議事録の整備の必要性,事業承継における議事録の役割,事業承継をスムーズに行う議事録の取り扱い方</small>
                </div>
                <div className="QuestionsAll__question">
                <p>中・長期的な事業計画書や経営計画書の策定</p>
                <small className="QuestionsAll__small">目標設定と事業ビジョンの明確化, 事業計画の構築と期間設定, リソースの調査と最適化, 予算計画と財務分析, リスクマネージメントと緊急時対応計画</small>
                </div>
                <div className="QuestionsAll__question">
                <p>資金繰り表の作成</p>
                <small className="QuestionsAll__small">資金繰り表とは,資金繰り表作成の方法,資金繰り表の読み方と分析,資金繰り表と事業承継,事業承継における資金繰り表の活用法</small>
                </div>
                <div className="QuestionsAll__question">
                <p>月次決算の早期作成体制の整備、予実管理体制の構築</p>
                <small className="QuestionsAll__small">財務データの収集と整理のスピードアップ, 決算締め作業フローの最適化, 情報システムの導入・活用, 予算管理と実績分析の定期的な実行, 経営者の意思決定に向けた情報提供体制の確立</small>
                </div>
                <div className="QuestionsAll__question">
                <p>部門別、製品・サービスごとの収益データの整備</p>
                <small className="QuestionsAll__small">部門ごとの財務データの整理,製品・サービスごとの売上データの分析,各部門の業績目標と実績の比較,マーケティング活動と収益の相関関係の検討,製品・サービスごとのコスト効率分析</small>
                </div>
                <div className="QuestionsAll__question">
                <p>各部署の責任者の適正配置、役割の明確化</p>
                <small className="QuestionsAll__small">責任者の適性評価,部署間コミュニケーションのスムーズ化,業務内容と人員配置の最適化,業績評価基準の設定と共有,スキルアッププログラムの設定と運営</small>
                </div>
                <div className="QuestionsAll__question">
                <p>セキュリティの適切な管理</p>
                <small className="QuestionsAll__small">従業員への情報保護方針の定期的な教育・研修,個人情報の保護とプライバシーポリシーの管理,セキュリティシステムの定期的な更新と改善,不正アクセスやリスクからの防護策,データ漏洩事故時の対応計画の策定と実施</small>
                </div>
                <h3>財務・改善</h3>
                <div className="QuestionsAll__question">
                <p>不適切な会計処理や税務処理はないか</p>
                <small className="QuestionsAll__small">適切な資産評価の実施,租税公課の遵守,法的な税制優遇措置の適用有無,適切な費用処理の確認,無形資産の評価・処理法の確認</small>
                </div>
                <div className="QuestionsAll__question">
                <p>未払い残業代ほか簿外債務はないか</p>
                <small className="QuestionsAll__small">労働時間記録の整備状況,未収集の社会保険料,租税公課の未払金,未払いの事業用不動産税,隠れた従業員の存在確認</small>
                </div>
                <div className="QuestionsAll__question">
                <p>継続的な不適切取引（不当な値引き、過剰仕入など）はないか</p>
                <small className="QuestionsAll__small">適切な価格設定の確認,取引量の適正性チェック,取引先との契約内容の確認,利益率の分析,内部統制のチェック</small>
                </div>
                <div className="QuestionsAll__question">
                <p>会計帳簿や決算書類などの保管</p>
                <small className="QuestionsAll__small">保管期間の確認,重要書類の整理・保存方法,電子的保存の利用,プライバシーと保護の考慮,保管場所の安全性確認</small>
                </div>
                <div className="QuestionsAll__question">
                <p>無駄な経費や、会社本来の事業に不要なものが混じっていないか</p>
                <small className="QuestionsAll__small">公私混用の検証,遊休資産の確認と適切な処分方法の立案,固定費や運転資本の最適化,事業と関連性の低い資産・経費の見直し,業績に貢献していないプロジェクトの再評価</small>
                </div>
                <div className="QuestionsAll__question">
                <p>帳簿価額と時価との乖離の大きいものがないか</p>
                <small className="QuestionsAll__small">財務諸表の詳細な分析, 不動産の評価, 株式の評価, 無形資産の評価, 事業承継における税務評価</small>
                </div>
                <div className="QuestionsAll__question">
                <p>有利子負債の返済</p>
                <small className="QuestionsAll__small">有利子負債の種類と特性, 返済計画の策定, リスケジュールとリストラクチャリング, 返済期限と利息の交渉, 返済のための資金調達方法</small>
                </div>
                <div className="QuestionsAll__question">
                <p>経営者保証を外す</p>
                <small className="QuestionsAll__small">経営者保証の役割とリスク, 経営者保証を外すタイミング, 経営者保証を外すための準備, 外部専門機関によるサポート, 経営者保証外し後の対策</small>
                </div>
                <div className="QuestionsAll__question">
                <p>合法的に効果的な節税対策</p>
                <small className="QuestionsAll__small">会社の所有形態による節税対策,事業承継と相続税の最適なタイミング,不動産等の固定資産の適切な評価,生命保険を活用した対策,株式の譲渡等を用いた節税戦略</small>
                </div>
                <h3>人事労務・改善</h3>
                <div className="QuestionsAll__question">
                <p>社員の離職率は高くないか・労務関係の書類の整理</p>
                <small className="QuestionsAll__small">労働契約書の内容確認,離職率の実態調査,労働時間の管理手法の確認,社員の福利厚生の状況調査,労働相談・苦情の記録分析</small>
                </div>
                <div className="QuestionsAll__question">
                <p>社会保険への加入の有無</p>
                <small className="QuestionsAll__small">社会保険の種類と加入要件,自己都合退職と社会保険,事業承継時の社会保険加入義務,経営者の社会保険加入手続き,加入していない場合の法律上の問題</small>
                </div>
                <div className="QuestionsAll__question">
                <p>労使協定の締結の有無</p>
                <small className="QuestionsAll__small">労使協定の締結プロセス, 労使協定の締結に必要な条件, 労使協定の有無が事業承継に及ぼす影響, 労使協定の内容とその更新方法, 労使協定締結に関する法律・規制</small>
                </div>
                <div className="QuestionsAll__question">
                <p>労務管理(出退勤管理など)を把握する仕組み構築</p>
                <small className="QuestionsAll__small">電子タイムカード導入,労働時間・休憩時間の記録管理,出退勤状態のリアルタイムモニタリング,過労状態検知と改善策の提案,法規制準拠のためのデータストレージ</small>
                </div>
                <div className="QuestionsAll__question">
                <p>人事評価制度や退職金制度などの整備</p>
                <small className="QuestionsAll__small">人事評価制度の設計,退職金制度の設計,人事評価制度と退職金制度との連動性,制度の運用と管理の方法,制度改正のタイミングと方法</small>
                </div>
                <div className="QuestionsAll__question">
                <p>標準業務についてのマニュアル化</p>
                <small className="QuestionsAll__small">業務内容の明確化,業務フローの整理と図化,マニュアル作成のプロセス策定,スタッフのトレーニングとマニュアルの活用法,マニュアルの更新と改善策導入</small>
                </div>
                <h3>契約・改善</h3>
                <div className="QuestionsAll__question">
                <p>各種取引の契約書類の整備</p>
                <small className="QuestionsAll__small">不動産の取引契約書の整備,リース物件の契約書の整備,賃貸物件の契約書の整備,会社所有の不動産の権利書の整理,勝本等の公認証書の整理</small>
                </div>
                <h3>取引先・改善</h3>
                <div className="QuestionsAll__question">
                <p>取引先との契約書や秘密保持契約書の整備</p>
                <small className="QuestionsAll__small">契約書の関係各方面の検討,契約書作成のための具体的手続き,秘密保持契約書の重要性とその機能,秘密保持契約書作成時に必要な配慮点,契約書と秘密保持契約書の管理安全策</small>
                </div>
                <div className="QuestionsAll__question">
                <p>取引先別の特徴や代替可能性の検討</p>
                <small className="QuestionsAll__small">取引先のビジネスモデルの理解,取引先との取引内容の把握,取引条件の評価と見直し,代替取引先の発掘と評価,代替可能性の時期とコストの検討</small>
                </div>
                <h3>顧客・改善</h3>
                <div className="QuestionsAll__question">
                <p>顧客との契約書の整理</p>
                <small className="QuestionsAll__small">契約書の所在確認,期間満了・解約に関する条項の確認,顧客との契約内容の確認,契約更新のスケジュール作成,書類のデジタル化・電子管理の推進</small>
                </div>
                <div className="QuestionsAll__question">
                <p>取引実績がわかる資料を顧客別に整備</p>
                <small className="QuestionsAll__small">顧客情報のデータベース作成,過去の取引履歴の整理とデータ化,顧客別の売上高・販売数量の分析,顧客別の取引項目のパターン分析,データ管理とプライバシー保護のためのセキュリティ対策</small>
                </div>
                <div className="QuestionsAll__question">
                <p>売掛金などの回収サイトの短縮化</p>
                <small className="QuestionsAll__small">売掛金の管理と追跡の強化,効率的なクレジットポリシーの確立,取引条件の見直し,回収業務の外部委託,先行き不確定要素への対応策の準備</small>
                </div>
                <div className="QuestionsAll__question">
                <p>支払いの遅れている顧客には支払い催促</p>
                <small className="QuestionsAll__small">催促の方法とタイミング,法的な対処法,顧客との良好な関係性の維持,債権回収の外部委託,遅延利息の適用可能性</small>
                </div>
                <div className="QuestionsAll__question">
                <p>反社会的勢力遮断への取組み</p>
                <small className="QuestionsAll__small">反社会的勢力との一切の関与禁止に対する意識強化,適切な背景調査を行う制度の構築,反社会的勢力に対するマネーの供給禁止の醸成,反社会的勢力との取引の発覚時の対応手順の確立,社会全体に対する反社会的勢力排除への協力とパートナーシップの推進</small>
                </div>
                <h3>見た目・改善</h3>
                <div className="QuestionsAll__question">
                <p>事務所内や工場内などを整理整頓し、つねに清掃の行き届いた状態</p>
                <small className="QuestionsAll__small">定期的な清掃スケジュールの作成と実施,不要な書類や道具の排除,整理整頓のルールの策定と周知,場所の適切なラベリング,安全確保のための清掃と整備</small>
                </div>
                <div className="QuestionsAll__question">
                <p>ウェブサイトの更新が止まったり、パンフレットが古すぎないか</p>
                <small className="QuestionsAll__small">最終更新日時の確認,製品・サービス情報の最新化,新規ニュース・情報更新の確認,パンフレット制作日のチェック,デザイン・レイアウトの古さ判断</small>
                </div>
                <div className="QuestionsAll__question">
                <p>従業員の制服が古いものを使い回していないか</p>
                <small className="QuestionsAll__small">制服の供給管理システムの確認,制服の摩耗度チェック,従業員のサイズ別制服在庫確認,新入社員への制服提供状況の確認,制服の更新サイクルの評価</small>
                </div>
            </div>
        </div>
    )
}

export default QuestionAll;