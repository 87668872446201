import { SignUpEmpty, SignUpEntity } from "./entity"
import { toInt } from "../../../lib/helper/valueCast";

export class ResponseMapper {
    private data;
    constructor(data: any) {
        this.data = data;
    }
    convert(): SignUpEntity {
        return this.data
        ?
        new SignUpEntity({
            id: String(this.data?.ID),
            fullName: String(this.data?.FullName),
            userType: toInt(this.data?.userType),
            isEmailConfirmed: Boolean(this.data?.IsEmailConfirmed)
        })
        :
        new SignUpEmpty();
    }
}