import AWS from "aws-sdk"
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { Navigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { TextField, Select, MenuItem, Snackbar, Button } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import { Room } from "../../../fetch/rooms/getConfirmed/entity";
import Header from "../../organisms/Header/component";
import ConfirmNeeded from "../../organisms/ConfirmNeeded/component";

import "./style.css";
import { fetchCreateCsrf } from "../../../fetch/csrf/create";
import { SignUpEntity } from "../../../fetch/users/signUpAuth/entity";
import { isPictureFile } from "../../../lib/helper/imegaHelper";

const bucketName = "65diary-image";
const bucketRegion = "us-west-1"
const identityPoolID = "us-west-1:0d55780b-9636-4114-bfcf-b22c1f6d88df";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
    isConfirmed: boolean;
    rooms: Room[];
    signUpAuth: (email: string, password: string, fullName: string, userType: number) => Promise<SignUpEntity>;
    createRoom: (title: string, thumbnailImage: string, userIDs: string[], csrf: string) => Promise<boolean>;
    signOut: () => Promise<boolean>;
};

type Followup1Props = {
    onClickNextStep: () => void;
    signUpAuth: (email: string, password: string, fullName: string, userType: number) => Promise<SignUpEntity>;
    users: FormProps[];
    setUsers: Dispatch<SetStateAction<FormProps[]>>;
}

type Followup2Props = {
    setFollowupStep: Dispatch<SetStateAction<number>>;
    createRoom: (title: string, thumbnailImage: string, userIDs: string[], csrf: string) => Promise<boolean>;
    users: FormProps[];
    roomForm: RoomFormProps;
    setRoomForm: Dispatch<SetStateAction<RoomFormProps>>
}

// type Followup3Props = {
//     users: FormProps[];
//     roomForm: RoomFormProps;
// }

type FormProps = {
    id: number;
    actualID: string;
    email: string;
    password: string;
    fullName: string;
    userType: string;
}

const emptyUser = {
    id: 0,
    actualID: "",
    email: "",
    password: "",
    fullName: "",
    userType: "1"
}

type UserFormProps = {
    form: FormProps;
    setUsers: Dispatch<SetStateAction<FormProps[]>>;
    removeUser: (index: number) => void;
    index: number;
}

const UserForm: React.FC<UserFormProps> = ({
    form,
    setUsers,
    removeUser,
    index
}) => {
    const onChangeForm = (type: string, index: number, value: string) => {
        setUsers((prev) => {
            return prev.map((user) => {
                if (user.id === index) {
                    return {...user, [type]: value};
                }
                return user;
            })
        });
    }
    const onChangeSelect = (event: SelectChangeEvent, index: number) => {
        setUsers((prev) => {
            return prev.map((user) => {
                if (user.id === index) {
                    return {...user, userType: event.target.value};
                }
                return user;
            })
        });
    }
    return(
        <div className="RegisterFollowup__userForm">
            <p>ユーザー{index+1}{"　"}<Button onClick={() => removeUser(form.id)} color="error" variant="contained">削除する</Button></p>
            <p>メールアドレス</p>
            <TextField
                color="warning"
                variant="outlined"
                className="RegisterFollowup__form"
                type="email"
                value={form.email || ""}
                onChange={(e) => onChangeForm("email", form.id, e.target.value)}
            />
            <p>お名前</p>
            <TextField
                color="warning"
                variant="outlined"
                className="RegisterFollowup__form"
                value={form.fullName || ""}
                onChange={(e) => onChangeForm("fullName", form.id, e.target.value)}
            />
            <p>親か子供か関係者か</p>
            <Select
                value={form.userType}
                onChange={(e) => onChangeSelect(e, form.id)}
            >
                <MenuItem value="1">親</MenuItem>
                <MenuItem value="2">子供</MenuItem>
                <MenuItem value="3">関係者</MenuItem>
            </Select>
            <p>パスワード</p>
            <TextField
                color="warning"
                variant="outlined"
                className="RegisterFollowup__form"
                type="password"
                value={form.password || ""}
                onChange={(e) => onChangeForm("password", form.id, e.target.value)}
            />
        </div>
    )
}

export const Followup1: React.FC<Followup1Props> = ({onClickNextStep, signUpAuth, users, setUsers}) => {
    const [isUserSnackbarDisplayed, setIsUserSnackbarDisplayed] = useState(false);
    const [userFail, setUserFail] = useState<string[]>([]);
    const addUser = () => {
        if (users.length > 10) return;
        let randomNumber: number;
        while(true) {
            randomNumber = Math.floor(Math.random() * 10000);
            if (!users.find((u) => u.id === randomNumber)) break;
        }
        setUsers((user) => {
            return [...user, {...emptyUser, id: randomNumber}]
        })
    }
    const removeUser = (index: number) => {
        setUsers((user) => {
            return user.filter((user) => user.id !== index)
        });
    }
    const registerUsers = async() => {
        const completedUsers = users.filter((user) => {
            return user.fullName && user.email && user.password && user.userType && (user.userType === "1" || user.userType === "2" || user.userType === "3")
        });
        let userResults: {userSuccess: boolean, userEmail: string, userID: string}[] = [];
        for (let user of completedUsers) {
            const result = await signUpAuth(user.email, user.password, user.fullName, Number(user.userType));
            userResults = [...userResults, { userSuccess: result.data.fullName !== "", userEmail: user.email, userID: result.data.id }];
        }
        const userAllSignuped = userResults.every((ur) => ur.userSuccess === true);
        // IDを入れ直す
        setUsers((prev) => {
            return prev.map((user) => {
                if (userResults.map((u) => u.userEmail).includes(user.email)) {
                    return {...user, actualID: userResults.find((u) => u.userEmail === user.email)?.userID || ""}
                }
                return user
            })
        })
        if (!userAllSignuped) {
            const failUserResult = userResults.filter((ur) => !ur.userSuccess).map((ur) => ur.userEmail);
            setUserFail(failUserResult);
            setIsUserSnackbarDisplayed(true);
            return;
        }
        onClickNextStep();
    }
    return (
        <>
            <h3>ユーザーを紹介する</h3>
            <div style={{lineHeight: 1.4}}>
                事業承継に関係ある親御さんやご子息がいらっしゃったら、今のうちに招待しましょう！
                <ol className="RegisterFollowup__followup1Explain">
                    <li>招待したい人がいたら、<Button color="primary" variant="contained" size="small" onClick={addUser}>ユーザーを追加する</Button> を押して、ユーザー情報を入力しましょう（ユーザーは後からでも招待できます）</li>
                    <li>入力し終わったら<Button color="warning" variant="contained" size="small">登録をする</Button> をおして、次のページでチャットルームに招待しましょう！</li>
                </ol>
                <small>※１：「メールアドレス」「お名前」「親か子供か関係者か」「パスワード」全てが入力されたユーザーしか登録されないので注意してください！</small>
            </div>
            { users.map((user, index) => {
                return (
                    <UserForm
                        form={user}
                        setUsers={setUsers}
                        removeUser={removeUser}
                        index={index}
                        key={`users_${user.id}`}
                    />
                )
            })}
            <br/>
            <Button onClick={addUser} color="primary" variant="contained">
                ユーザーを追加する
            </Button>
            <br/><br/>
            <Button onClick={registerUsers} color="warning" variant="contained">
                { users.length
                  ? <>登録する</>
                  : <>招待せず登録する</>
                }
            </Button>
            <Snackbar
                open={isUserSnackbarDisplayed}
                message={`${userFail.join(", ")} のユーザー登録に失敗しました`}
                autoHideDuration={300000}
            />
        </>
    )
}

type RoomFormProps = {
    title: string;
    thumbnailImage: string;
}

const Followup2: React.FC<Followup2Props> = ({users, createRoom, setFollowupStep, roomForm, setRoomForm}) => {
    AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolID
        })
    });
    const s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: bucketName }
    });
    const [csrf, setCsrf] = useState<string>("");
    const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
    const onChangeThumbnailImageFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsImageLoading(true);
        const files = e.target.files;
       if (!files){
        setIsImageLoading(false);
        return;
       }
       const file = files[0];
       if (!isPictureFile(file.name)) {
        setIsImageLoading(false);
        return;
       }
       const randomNumber = Math.floor(Math.random() * 1000000);
       const photoKey = encodeURIComponent("images") + "/" + String(Date.now()) + String(randomNumber) + "_" + file.name;
       const upload = new AWS.S3.ManagedUpload({
        params: {
            Bucket: bucketName,
            Key: photoKey,
            Body: file,
        }
       });
       const promise = upload.promise();
       promise.then((_) => {
        const url = `https://65diary-image.s3.us-west-1.amazonaws.com/${photoKey}`
        console.log("success : ", url);
        setIsImageLoading(false);
        setRoomForm((prev) => {
            return {...prev, thumbnailImage: url};
        });
       }).catch((err) => {
        setIsImageLoading(false);
        console.log("error", err);
       })
    }
    const onClickSubmit = async() => {
        if (!roomForm.thumbnailImage || !roomForm.title) return;
        const result = await createRoom(roomForm.title, roomForm.thumbnailImage, users.map((user) => user.actualID), csrf);
        if (result) {
            setFollowupStep(2);
        }
    }
    useEffect(() => {
        async function doGet() {
            const result = await fetchCreateCsrf();
            if (result.error) return;
            setCsrf(result.data.validator);
        }
        doGet();
    }, [])
    return (
        <>
            <h3>部屋を作る</h3>
            <p>部屋の中で事業承継に関するお話ができます。ここではタイトルとサムネイル写真を登録しましょう。</p>
            <div className="RegisterFollowup__userForm">
                <p>タイトル</p>
                <TextField
                    color="warning"
                    variant="outlined"
                    className="RegisterFollowup__form"
                    onChange={(e) => {
                        setRoomForm((prev) => {
                            return {...prev, title: e.target.value}
                        })
                    }}
                />
                <p>
                    サムネイル写真
                    <br/>
                    <small>サムネイル写真は必ず登録してください（jpg, jpeg, png ファイルのみ）</small>
                </p>
                <input
                    type="file"
                    className="RegisterFollowup__form"
                    onChange={onChangeThumbnailImageFile}
                />
                <br/><br/>
                <Button
                    variant="contained"
                    color="warning"
                    disabled={isImageLoading || !roomForm.thumbnailImage}
                    onClick={onClickSubmit}
                >
                    部屋を作成する
                </Button>
            </div>
        </>
    )
}

// const Followup3: React.FC<Followup3Props> = ({users, roomForm}) => {
//     return (
//         <>
//             <h3>初期設定が完了しました！</h3>
//             <div className="RegisterFollowup__userForm">
//                 <h5>登録ユーザー一覧</h5>
//                     {users.map((user) => {
//                         return(
//                             <div>
//                                 <p>メールアドレス:{user.email}</p>
//                                 <p>名前：{user.fullName}</p>
//                                 <p>{user.userType === "1" ? "親" : "子"}</p>
//                             </div>
//                         )
//                     })}
//                 <h5>登録した部屋</h5>
//             </div>
//         </>
//     );
// }

const RegisterFollowup: React.FC<Props> = ({
    isLogin,
    isAuthLoading,
    isConfirmed,
    rooms,
    signUpAuth,
    createRoom,
    signOut
}) => {
    const [users, setUsers] = useState<FormProps[]>([]);
    const [roomForm, setRoomForm] = useState<RoomFormProps>({} as RoomFormProps);
    const [followupStep, setFollowupStep] = useState<number>(0);
    const [isConfirmSnackbarDisplayed, setIsConfirmSnackbarDisplayed] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const confirm = Boolean(searchParams.get("confirm"));
    const onClickCloseConfirmSnackbar = () => {
        setIsConfirmSnackbarDisplayed(false);
    }
    useEffect(() => {
        if (confirm) setIsConfirmSnackbarDisplayed(true);
    }, [confirm]);
    if (!isLogin && !isAuthLoading) return <Navigate to="/users/sign_in"/>
    if (rooms.length) return <Navigate to="/mypage"/>
    return(
        <div className="RegisterFollowup">
            <Header isLogin={isLogin} isAuthLoading={isAuthLoading} signOut={signOut}/>
            <div className="RegisterFollowup__main">
                {!isConfirmed
                ?
                    <ConfirmNeeded isConfirmed={isConfirmed}/>
                :
                    followupStep === 0
                    ?
                        <Followup1
                            onClickNextStep={() => setFollowupStep(1)}
                            signUpAuth={signUpAuth}
                            users={users}
                            setUsers={setUsers}
                        />
                    :
                        <Followup2
                            setFollowupStep={setFollowupStep}
                            createRoom={createRoom}
                            users={users}
                            roomForm={roomForm}
                            setRoomForm={setRoomForm}
                        />
                }
            </div>
            <Snackbar
                open={isConfirmSnackbarDisplayed}
                autoHideDuration={3000}
                message="認証に成功しました！"
                onClose={onClickCloseConfirmSnackbar}
            />
        </div>
    )
}

export default RegisterFollowup;