import React, { useEffect, useState } from "react";
import { Navigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import Header from "../../organisms/Header/component";

import "./style.css";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
    isConfirmed: boolean;
    confirmUser: (confirmToken: string) => Promise<boolean>;
    signOut: () => Promise<boolean>;
}

const UserConfirm: React.FC<Props> = ({isLogin, isAuthLoading, isConfirmed, confirmUser, signOut}) => {
    const [confirmResult, setConfirmResult] = useState<boolean>(true);
    const [searchParams] = useSearchParams();
    const confirmToken = searchParams.get("confirmation_token");
    useEffect(() => {
        async function doGet() {
            if (!confirmToken || !isLogin) return;
            const result = await confirmUser(confirmToken);
            setConfirmResult(result);
        }
        doGet();
    }, [confirmToken, isLogin])
    if (isConfirmed) {
        return <Navigate to="/users/followup?confirm=true"/>
    }
    if (!isLogin && !isAuthLoading) {
        return <Navigate to="/users/sign_in"/>
    }
    return (
        <div className="UserConfirm">
            <Header isLogin={isLogin} isAuthLoading={isAuthLoading} signOut={signOut}/>
            <div className="UserConfirm__main">
                { confirmResult
                ?
                    <p>
                        ユーザー認証中...<CircularProgress/>
                    </p>
                :
                    <p>
                        ユーザー認証に失敗しました...
                    </p>
                }
            </div>
        </div>
    );
}

export default UserConfirm;