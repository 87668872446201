export type Room = {
    id: string;
    title: string;
    thumbnailImage: string;
    ownerID: string;
}

export class CreateRoomEntity {
    data: Room;
    error?: string;
    constructor(data: Room, error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

const emptyRoom = {
    id: "",
    title: "",
    thumbnailImage: "",
    ownerID: ""
}

export class CreateRoomEmpty extends CreateRoomEntity {
    constructor() {
        super(emptyRoom)
    }
}

export class CreateRoomError extends CreateRoomEntity {
    constructor(errorMessage: string) {
        super(emptyRoom, errorMessage);
    }
}