import { CreateEntity, CreateError } from "./entity";
import { ResponseMapper } from "./responseMapper";
import { fetchUrl } from "../../../lib/const/url"

export async function fetchCreateCsrf(): Promise<CreateEntity> {
    try {
        const response = await fetch(`${fetchUrl}/csrf/create`, {
            method: "GET",
            credentials: "include"
        })
        .then((data) => data.json());
        if (response.message) {
            const result = new CreateError(response.message);
            return result;
        }
        const entity = new ResponseMapper(response).convert();
        return entity;
    } catch (e) {
        console.log(e);
        return new CreateError(JSON.stringify(e));
    }
}