import { toInt } from "../../../lib/helper/valueCast";
import { CookieUserEntity, CookieUserEmpty } from "./entity";

export class ResponseMapper {
    private data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert(): CookieUserEntity{
        return this.data
        ?
            new CookieUserEntity({
                id: String(this.data?.ID),
                email: String(this.data?.Email),
                fullName: String(this.data?.FullName),
                userType: toInt(this.data?.UserType),
                thumbnailImage: String(this.data?.ThumbnailImage),
                isEmailConfirmed: Boolean(this.data?.IsEmailConfirmed),
                fontSize: toInt(this.data?.FontSize),
                createdAt: String(this.data?.CreatedAt)
            })
        :
            new CookieUserEmpty();
    }
}