import { GetQuestionTypeEntity, GetQuestionTypeEmpty } from "./entity"
import { toInt } from "../../../lib/helper/valueCast";

export class ResponseMapper{
    private data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert() : GetQuestionTypeEntity {
        return this.data.QuestionTypes && Array.isArray(this.data.QuestionTypes)
        ? new GetQuestionTypeEntity(
            this.data.QuestionTypes.map((d: any) => ({
                id: toInt(d?.ID),
                title: String(d?.Title),
                userType: toInt(d?.UserType),
                createdAt: String(d?.CreatedAt)
            }))
        )
        : new GetQuestionTypeEmpty()
    }
}