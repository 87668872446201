import React, {useState} from "react";
import { Navigate } from "react-router";
import { Button, TextField } from "@mui/material";
import { Textarea } from "@mui/joy";

import { fetchCreateOpinion } from "../../../fetch/opinions/create";
import { QueryBuilder } from "../../../fetch/opinions/create/queryBuilder";

import "./style.css";
import Header from "../../organisms/Header/component";

type OpinionProps = {
    title: string;
    content: string;
    fullName: string;
    email: string;
}

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
}

const Opinion: React.FC<Props> = ({
    isLogin,
    isAuthLoading
}) => {
    const [opinion, setOpinion] = useState<OpinionProps>({} as OpinionProps);
    const [opinionSend, setOpinionSend] = useState<boolean>(false);
    const sendOpinion = async() => {
        if (!opinion.content || !opinion.email || !opinion.fullName || !opinion.fullName) return;
        const queryBuilder = new QueryBuilder();
        queryBuilder.setContent(opinion.content);
        queryBuilder.setEmail(opinion.email);
        queryBuilder.setFullName(opinion.fullName);
        queryBuilder.setTitle(opinion.title);
        const result = await fetchCreateOpinion(queryBuilder.build());
        setOpinion({} as OpinionProps);
        setOpinionSend(result.data.status === "success");
    }
    if (opinionSend) return <Navigate to="/"></Navigate>
    return (
        <div className="Opinion">
            <Header isLogin={isLogin} isAuthLoading={isAuthLoading} />
            <div className="Opinion__main">
                <h3>意見を問い合わせる</h3>
                <p>メール</p>
                <TextField
                    type="email"
                    value={opinion.email}
                    onChange={(e) => { setOpinion((prev) => ({...prev, email: e.target.value})) }}
                />
                <p>お名前</p>
                <TextField
                    value={opinion.fullName}
                    onChange={(e) => { setOpinion((prev) => ({...prev, fullName: e.target.value})) }}
                />
                <p>タイトル</p>
                <TextField
                    value={opinion.title}
                    onChange={(e) => { setOpinion((prev) => ({...prev, title: e.target.value})) }}
                />
                <p>内容</p>
                <Textarea
                    minRows={5}
                    maxRows={10}
                    value={opinion.content}
                    onChange={(e) => { setOpinion((prev) => ({...prev, content: e.target.value})) }}
                />
                <br/>
                <Button
                    variant="contained"
                    color="warning"
                    onClick={sendOpinion}
                >
                    送信する
                </Button>
            </div>
        </div>
    )
};

export default Opinion;