export class QueryBuilder {
    private query: any;
    constructor() {
        this.query = {};
    }
    setTitle(title: string) {
        this.query.title = title;
    }
    setThumbnailImage(thumbnailImage: string) {
        this.query.thumbnailImage = thumbnailImage;
    }
    setUserIDs(userIds: string[]) {
        this.query.userIds = userIds;
    }
    setCsrf(csrf: string) {
        this.query.csrf = csrf;
    }
    build() {
        return JSON.stringify(this.query);
    }
}