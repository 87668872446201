import { GetConfirmedRoomEntity, GetConfirmedRoomEmpty } from "./entity"

export class ResponseMapper {
    private data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert(): GetConfirmedRoomEntity {
        return this.data.Rooms && Array.isArray(this.data.Rooms)
        ? new GetConfirmedRoomEntity(
            this.data.Rooms.map((d: any) => ({
                id: String(d?.ID),
                title: String(d?.Title),
                thumbnailImage: String(d?.ThumbnailImage),
                ownerID: String(d?.OwnerID),
                createdAt: String(d?.CreatedAt)
            }))
        )
        : new GetConfirmedRoomEmpty();
    }
}