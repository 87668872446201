import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Button, TextField } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Question } from "../../../fetch/questions/getAll/entity";
import { QuestionType } from "../../../fetch/questionTypes/getAll/entity";
import { Message } from "../../../fetch/messages/getRoomQuestion/entity";

import "./style.css";
import { Link } from "react-router-dom";

export type Props = {
    questions: Question[];
    questionTypes: QuestionType[];
    questionMessages?: Message[];
    onClickSelectQuestion?: (id: number) => void;
}

const QUESTION_COUNT_STEP = 10;

type QuestionTypeProps = {
    questionMessages: Message[];
    questionID: number;
}

const QuestionTypeDropDown: React.FC<QuestionTypeProps> = ({
    questionMessages,
    questionID
}) => {
    const currentQuestionMessages = questionMessages.filter((qt) => qt.questionID === questionID);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return(
        <>
            <Button
                variant="contained"
                color="primary"
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                見てみる
            </Button>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                { currentQuestionMessages.map((cqm) => {
                    return (
                        <MenuItem key={cqm.id}>
                            <Link to={`/messages/${cqm.id}`}>
                                {cqm.createdAt.slice(0, 10)} : {cqm.content.slice(0, 10)}...
                            </Link>
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )
}

const QuestionFilter: React.FC<Props> = ({
    questions,
    questionTypes,
    questionMessages,
    onClickSelectQuestion
}) => {
    const [currentQuestionType, setCurrentQuestionType] = useState<number>(0);
    const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);
    const [searchWord, setSearchWord] = useState<string>("");
    const [searchCount, setSearchCount] = useState(QUESTION_COUNT_STEP);
    const onClickQuestionType = (questionTypeID: number) => {
        setSearchCount(QUESTION_COUNT_STEP);
        setCurrentQuestionType(questionTypeID);
        setSearchWord("")
        if (questionTypeID !== 0) {
            setFilteredQuestions(questions?.filter((q) => q.typeID === questionTypeID) || []);
            return;
        }
        setFilteredQuestions(questions);
    }
    const searchQuestion = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchWord(e.target.value);
        setSearchCount(QUESTION_COUNT_STEP);
        if (!e.target.value) {
            if (currentQuestionType === 0) {
                setFilteredQuestions(questions);
                return;
            }
            setFilteredQuestions(questions?.filter((q) => q.typeID === currentQuestionType) || []);
            return;
        }
        if (currentQuestionType === 0) {
            setFilteredQuestions(questions?.filter((fq) => {
                return fq.title.includes(e.target.value)
            }) || []);;
            return;
        }
        setFilteredQuestions(questions?.filter((q) => q.typeID === currentQuestionType)
        .filter((fq) => {
            return fq.title.includes(e.target.value)
        }) || []);
    }
    const questionMessageQuestions = Array.from(new Set(questionMessages?.map((qm) => qm.questionID).filter((qm) => Boolean(qm)) || []));
    useEffect(() => {
        if (!questions) return;
        setFilteredQuestions(questions);
    }, [questions]);
    return (
        <div className="QuestionFilter">
            <TextField
                color="warning"
                value={searchWord}
                onChange={(e) => searchQuestion(e)}
                placeholder="話す事業継承に関する知見を検索する"
                className="QuestionFilter__searchForm"
            />
            <br/>
            <div className="QuestionFilter__questionTypes">
                { questionTypes.map((qt) => {
                    return (
                        <Button
                            key={`questionType__${qt.id}`}
                            onClick={() => {
                                if (currentQuestionType === qt.id){
                                    onClickQuestionType(0)
                                    return;
                                }
                                onClickQuestionType(qt.id)
                            }}
                            className="QuestionFilter__questionType"
                            variant={ qt.id === currentQuestionType ? "contained" : "outlined"}
                        >
                            {qt.title}
                        </Button>
                    )
                }) }
            </div>
            <div className="QuestionFilter__questions">
                { filteredQuestions.slice(0, searchCount).map((question) => {
                    return (
                        <div key={`quetion_${question.id}`} className={classNames("QuestionFilter__question",
                            [questionMessageQuestions.includes(question.id) && "QuestionFilter__question--selected"]
                        )}>
                            <div className="QuestionFilter__quetionLeft">
                                <span>
                                    {question.title}
                                    <br/>
                                    <span className="QuestionFilter__subTitle">{question.content}など...</span>
                                </span>
                                <br/>
                                <small className="QuestionFilter__questionTypeSmall">{question.typeTitle}</small>
                            </div>
                            { questionMessages && questionMessageQuestions.includes(question.id) &&
                                <QuestionTypeDropDown questionMessages={questionMessages} questionID={question.id} />
                            }
                            { onClickSelectQuestion &&
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() => onClickSelectQuestion(question.id)}
                                >
                                    選択する
                                </Button>
                            }
                        </div>
                    )
                }) }
            </div>
            <br/>
            { filteredQuestions.length > 10 &&
                <Button
                    variant="contained"
                    color="warning"
                    onClick={() => setSearchCount((prev) => prev += QUESTION_COUNT_STEP)}
                >
                    もっと見る
                </Button>
            }
        </div>
    )
}

export default QuestionFilter;