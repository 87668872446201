export type Room = {
    id: string;
    title: string;
    thumbnailImage: string;
    ownerID: string;
    createdAt: string;
}

export class GetConfirmedRoomEntity {
    data: Room[];
    error?: string;
    constructor(data: Room[], error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

export class GetConfirmedRoomEmpty extends GetConfirmedRoomEntity {
    constructor() {
        super([])
    }
}

export class GetConfirmedRoomError extends GetConfirmedRoomEntity {
    constructor(errorMessage: string) {
        super([], errorMessage)
    }
}