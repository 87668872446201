import React, { useState } from "react";
import { Navigate, useParams } from "react-router";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { SignUpEntity } from "../../../fetch/users/signUpAuth/entity";
import { fetchInviteRoom } from "../../../fetch/rooms/invite";
import { QueryBuilder } from "../../../fetch/rooms/invite/queryBuilder";
import { Room } from "../../../fetch/rooms/getConfirmed/entity";

import Header from "../../organisms/Header/component";
import { Followup1 } from "../RegisterFollowup/component";

import "../RegisterFollowup/style.css";
import "./style.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

type FormProps = {
    id: number;
    actualID: string;
    email: string;
    password: string;
    fullName: string;
    userType: string;
}

type InviteUserProps = {
    signUpAuth: (email: string, password: string, fullName: string, userType: number) => Promise<SignUpEntity>;
    rooms: Room[];
    currentUserID: string;
    isAuthLoading: boolean;
    isLogin: boolean;
    signOut: () => Promise<boolean>;
}

const InviteUser: React.FC<InviteUserProps> = ({
    signUpAuth,
    rooms,
    currentUserID,
    isAuthLoading,
    isLogin,
    signOut
}) => {
    const [pageComplete, setPageComplete] = useState<boolean>(false);
    const [users, setUsers] = useState<FormProps[]>([]);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const { roomID } = useParams<{roomID: string}>();
    const currentRoom = rooms.map((r) => r.id).includes(roomID || "");
    const onClickNextStep = async () => {
        if (!users.length) return;
        setDialogOpen(true);
    }
    const onClickInviteUsers = () => {
        users.forEach(async(user) => {
            const queryBuilder = new QueryBuilder();
            queryBuilder.setRoomID(roomID || "");
            queryBuilder.setUserID(user.actualID);
            await fetchInviteRoom(queryBuilder.build());
        })
        setPageComplete(true);
    }
    if (pageComplete) return <Navigate to="/mypage" />
    if (!currentRoom) return <Navigate to="/mypage"/>
    if (rooms.find((r) => r.id === roomID)?.ownerID !== currentUserID) return <Navigate to="/mypage"/>
    return (
        <>
            <div className="InviteUser">
                <Header isAuthLoading={isAuthLoading} isLogin={isLogin} signOut={signOut}/>
                <div className="InviteUser__main">
                    <Link to="/mypage">戻る</Link>
                    <div className="RegisterFollowup InviteUser__content">
                    <Followup1
                        signUpAuth={signUpAuth}
                        users={users}
                        setUsers={setUsers}
                        onClickNextStep={onClickNextStep}
                    />
                    </div>
                </div>
            </div>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"以下の情報で登録しました。このまま招待しますか？"}
                </DialogTitle>
                <DialogContent id="alert-dialog-description">
                    {users.map((user) => {
                        return <p key={user.actualID}>{user.email} {user.fullName}さん</p>
                    })}
                    を登録します。
                    <br/><br/>
                    <Button color="primary" variant="contained" onClick={onClickInviteUsers}>
                        登録する
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default InviteUser;