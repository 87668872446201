import { ConfirmEmailEntity, ConfirmEmailError } from "./entity"
import { ResponseMapper } from "./responseMapper"
import { fetchUrl } from "../../../lib/const/url"

export async function fetchConfirmEmailUser(confirmToken: string): Promise<ConfirmEmailEntity> {
    try {
        const response = await fetch(`${fetchUrl}/confirm_users/confirm_email?confirmation_token=${confirmToken}`, {
            method: "GET",
            credentials: "include"
        }).then((data) => data.json());
        if (response.message) {
            return new ConfirmEmailError(response.message);
        }
        const entity = new ResponseMapper(response).convert();
        return entity;
    } catch(e) {
        console.log(e)
        return new ConfirmEmailError(JSON.stringify(e));
    }
}