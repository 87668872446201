import React from "react";
import Header from "../../organisms/Header/component";

import BusinessIcon from '@mui/icons-material/Business';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PersonIcon from '@mui/icons-material/Person';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SchoolIcon from '@mui/icons-material/School';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import "./style.css";
import { Link } from "react-router-dom";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
    signOut: () => Promise<boolean>;
}

const Top: React.FC<Props> = ({
    isLogin,
    isAuthLoading,
    signOut
}) => {
    return (
        <div className="Top">
            <Header isAuthLoading={isAuthLoading} isLogin={isLogin} signOut={signOut}/>
            <div className="Top__header">
                <div>
                    <h1>
                        事業承継サポートとは？
                    </h1>
                    <h3>
                        事業承継に関する話題を親子や親戚の間で話したり、ChatGPTに意見を聞けるウェブサイトです
                    </h3>
                </div>
                <BusinessIcon className="Top__headerIcon"/>
            </div>
            <div className="Top__section1">
                <h1 className="Top__section1Title">事業継承でこんな悩みはありませんか？</h1>
                <div className="Top__section1Content">
                    <div className="Top__section1Senior">
                        <img src="https://65diary-image.s3.us-west-1.amazonaws.com/images/business_kaisya_tatamu.png" height="300" alt="悩む経営者"/>
                        <h3>事業承継で悩める経営者</h3>
                        <ul className="Top__section1Text">
                            <li>経営を息子などに引き継いでもらいたいけど、話すとっかかりがない</li>
                            <li>引き継いでくれないならM&Aをして高い価値で会社を売ることも考えているが、本当に売れるか自信がない</li>
                        </ul>
                    </div>
                </div>
                <div className="Top__section1Answer">
                    <ArrowForwardIcon className="Top__section1ArrowRight"/>
                    <h3 className="Top__section1AnswerText">そんな心配のために2つの機能を備えたSNSです！</h3>
                </div>
            </div>
            <div className="Top__section2">
                <h1 className="Top__section2Title">機能</h1>
                <div className="Top__section2Content">
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">1：経営に関する知見の共有</h3>
                        <PersonIcon className="Top__section2icon"/>
                        <h4 className="Top__section2subTitle">
                            親族と事業継承に関する知見について共有したり、話せる
                            <br/>
                            <small className="Top__section2smallTitle">ご子息に経営をする意思があるか探りを入れられる / 自分がいざとなった時のメモ代わりになる</small>
                        </h4>
                    </div>
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">2：ChatGPTが搭載されている</h3>
                        <AutoAwesomeIcon className="Top__section2icon"/>
                        <h4 className="Top__section2subTitle">
                            今話題のChatGPTに経営の方法について聞ける
                            <br/>
                            <small className="Top__section2smallTitle">M&Aのデューディリジェンスで必要になる知識全般について、今の経営状況の改善案を聞ける</small>
                        </h4>
                    </div>
                </div>
                <Link to="/questionsAll">話せる事業継承に関する知見一覧はこちら</Link>
                <br/>
                <br/>
                <h1 className="Top__section2Title">使い方のステップ</h1>
                <div className="Top__section2Content">
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">Step1</h3>
                        <PersonIcon className="Top__section2icon"/>
                        <h4 className="Top__section2subTitle">ユーザー登録</h4>
                    </div>
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">Step2</h3>
                        <MeetingRoomIcon className="Top__section2icon"/>
                        <h4 className="Top__section2subTitle">部屋を作成 ＆ 他ユーザーを招待</h4>
                    </div>
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">Step3</h3>
                        <QuestionAnswerIcon className="Top__section2icon"/>
                        <h4 className="Top__section2subTitle">部屋内で、事業継承に関する知見について話したり、ChatGPTの意見を聞きましょう！</h4>
                        <br/>
                    </div>
                </div>
                <br/>
                <h1 className="Top__section2Title">他のチャットアプリより、何がいいか？</h1>
                <div className="Top__section2Content">
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">1</h3>
                        <QuestionMarkIcon className="Top__section2icon"/>
                        <h4 className="Top__section2subTitle">事業承継に関して何をまとめればいいか分かる</h4>
                        <p className="Top__section2smallTitle">事業継承に関する知見が書いてあるので、１つ１つ知識を残しましょう</p>
                    </div>
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">2</h3>
                        <SchoolIcon className="Top__section2icon"/>
                        <h4 className="Top__section2subTitle">ChatGPTに自分が書いた事業承継に関する会話に対する意見をもらえる</h4>
                    </div>
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">3</h3>
                        <AutoStoriesIcon className="Top__section2icon"/>
                        <h4 className="Top__section2subTitle">後から事業継承に関する知識を調べやすい</h4>
                        <p className="Top__section2smallTitle">話された事業継承に関する知見ごとで知識を検索できます</p>
                    </div>
                </div>
                <Link to="/questionsAll">話せる事業継承に関する知見一覧はこちら</Link>
                <br/><br/>
                <Link to="/usage">使い方の説明はこちらにも</Link>
                <br/><br/>
                <h1 className="Top__section2Title">使い方</h1>
                <div className="Top__section2Content">
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">Step1</h3>
                        <img src="https://65diary-image.s3.us-west-1.amazonaws.com/images/65diary_explain_register1.png" width="80%" alt="登録する" className="Top__section2image"/>
                        <h4 className="Top__section2subTitle"><Link to="/users/sign_in">こちら</Link>からユーザー登録します。</h4>
                    </div>
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">Step2</h3>
                        <img src="https://65diary-image.s3.us-west-1.amazonaws.com/images/65diary_explain_register2.png" width="80%" alt="招待する" className="Top__section2image"/>
                        <h4 className="Top__section2subTitle">事業承継に関係する親戚や親族を招待します（初期設定では最大１０人まで）</h4>
                    </div>
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">Step3</h3>
                        <img src="https://65diary-image.s3.us-west-1.amazonaws.com/images/65diary_explain_register3.png" width="80%" alt="部屋を作る" className="Top__section2image"/>
                        <h4 className="Top__section2subTitle">部屋を作ります</h4>
                    </div>
                </div>
                <div className="Top__section2Content">
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">Step4</h3>
                        <img src="https://65diary-image.s3.us-west-1.amazonaws.com/images/65diary_explain_top1.png" width="80%" alt="トップページ１" className="Top__section2image"/>
                        <h4 className="Top__section2subTitle">Step1〜3の登録が完了すると、トップページに遷移します。ここからも招待できます</h4>
                    </div>
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">Step5</h3>
                        <img src="https://65diary-image.s3.us-west-1.amazonaws.com/images/65diary_explain_top2.png" width="80%" alt="トップページ２" className="Top__section2image"/>
                        <h4 className="Top__section2subTitle">トップページの下の方に、話せる事業継承に関する知見一覧・その事業継承に関する知見を話したかどうかが表示されます</h4>
                    </div>
                    <div className="Top__section2step">
                        <h3 className="Top__section2title">Step6</h3>
                        <img src="https://65diary-image.s3.us-west-1.amazonaws.com/images/65diary_explain_message3.png" width="80%" alt="メッセージ" className="Top__section2image"/>
                        <h4 className="Top__section2subTitle">部屋に入れば、メッセージが入力できます。事業承継に関するメッセージも入力できます。<br/>メッセージの詳細からChatGPTの意見も聞けます。</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Top;