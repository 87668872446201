export type User = {
    id: string;
    email: string;
    fullName: string;
    userType: number;
    thumbnailImage: string;
    isEmailConfirmed: boolean;
    fontSize: number;
    createdAt: string;
}

export class CookieUserEntity {
    data: User;
    error?: string
    constructor(data: User, error?: string) {
        this.data = data;
        if(error) this.error = error;
    }
}

const emptyUser: User = {
    id: "",
    email: "",
    fullName: "",
    userType: 0,
    thumbnailImage: "",
    isEmailConfirmed: false,
    fontSize: 0,
    createdAt: ""
};

export class CookieUserEmpty extends CookieUserEntity {
    constructor() {
        super(emptyUser);
    }
}

export class CookieUserError extends CookieUserEntity {
    constructor(errorMessage: string) {
        super(emptyUser, errorMessage);
    }
}