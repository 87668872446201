import { GetQuestionsEntity, GetQuestionsEmpty } from "./entity";

export class ResponseMapper {
    private data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert(): GetQuestionsEntity {
        return this.data.Questions && Array.isArray(this.data.Questions)
        ? new GetQuestionsEntity(
            this.data.Questions.map((d: any) => ({
                id: d?.ID,
                title: d?.Title,
                content: d?.Content,
                typeID: d?.TypeID,
                typeTitle: d?.TypeTitle,
                typeUser: d?.TypeUser,
                createdAt: d?.CreatedAt
            }))
        )
        : new GetQuestionsEmpty();
    }
}