import React, { useState, useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Top from "./components/templates/Top/component";
import SignIn from './components/templates/SignIn/component';
import SignUp from "./components/templates/SignUp/component"
import Terms from "./components/templates/Terms/component"
import Privacy from './components/templates/Privacy/component';
import Usage from './components/templates/Usage/component';
import QuestionAll from './components/templates/Questions/component';

import UserConfirm from './components/templates/UserConfirm/component';
import RegisterFollowup from './components/templates/RegisterFollowup/component';
import MyPage from "./components/templates/MyPage/component";
import Message from "./components/templates/Message/component";
import Room from "./components/templates/Room/component";
import InviteUser from './components/templates/InviteUser/component';
import UpdateUser from './components/templates/UpdateUser/component';
import InviteRegisteredUser from './components/templates/InviteRegisteredUser/component';
import Opinion from "./components/templates/Opinion/component";

import { Question } from "./fetch/questions/getAll/entity";
import { fetchGetQuestions } from "./fetch/questions/getAll";
import { QuestionType } from './fetch/questionTypes/getAll/entity';
import { fetchQuestionTypes } from "./fetch/questionTypes/getAll";

import { useRoom } from './hooks/useRoom';
import { useUser } from './hooks/useUser';

function App() {

  const {
    isLogin, isConfirmed, isLoading : isAuthLoading, user, error : userError,
    signIn, signUp, signOut, updateUser, confirmUser, signUpAuth, getRoomConfirmedUser
  } = useUser();

  const {
    rooms, isLoading : isRoomLoading, error : roomError,
    getConfirmedRoom, createRoom, getRoom, updateRoom
  } = useRoom(isLogin, isConfirmed);

  const [questions, setQuestions] = useState<Question[]>([])
  const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([]);

  const router = createBrowserRouter([
    {
      path: "/users/sign_in",
      element: (
        <SignIn isLogin={isLogin} isAuthLoading={isAuthLoading} signIn={signIn} />
      )
    },
    {
      path: "/users/sign_up",
      element: (
        <SignUp isLogin={isLogin} isAuthLoading={isAuthLoading} signUp={signUp} />
      )
    },
    {
      path: "/users/confirm",
      element: (
        <UserConfirm isLogin={isLogin} isAuthLoading={isAuthLoading} isConfirmed={isConfirmed} confirmUser={confirmUser} signOut={signOut}/>
      )
    },
    {
      path: "/",
      element: (
        <Top isLogin={isLogin} isAuthLoading={isAuthLoading} signOut={signOut}/>
      )
    },
    {
      path: "/terms",
      element: (
        <Terms isLogin={isLogin} isAuthLoading={isAuthLoading}/>
      )
    },
    {
      path: "/privacy",
      element: (
        <Privacy isLogin={isLogin} isAuthLoading={isAuthLoading}/>
      )
    },
    {
      path: "/usage",
      element: (
        <Usage isLogin={isLogin} isAuthLoading={isAuthLoading}/>
      )
    },
    {
      path: "/questionsAll",
      element: (
        <QuestionAll isLogin={isLogin} isAuthLoading={isAuthLoading}/>
      )
    },
    {
      path: "/opinions",
      element: (
        <Opinion isLogin={isLogin} isAuthLoading={isAuthLoading} />
      )
    },
    {
      path: "/users/followup",
      element: (
        <RegisterFollowup
          isLogin={isLogin}
          isAuthLoading={isAuthLoading}
          isConfirmed={isConfirmed}
          rooms={rooms}
          signUpAuth={signUpAuth}
          createRoom={createRoom}
          signOut={signOut}
        />
      )
    },
    {
      path: "/mypage",
      element: (
        <MyPage
          isLogin={isLogin}
          isAuthLoading={isAuthLoading}
          isConfirmed={isConfirmed}
          rooms={rooms}
          getRoomConfirmedUser={getRoomConfirmedUser}
          questions={questions}
          questionTypes={questionTypes}
          currentUserID={user.id}
          signOut={signOut}
        />
      )
    },
    {
      path: "/messages/:messageID",
      element: (
        <Message
          roomID={rooms.length ? rooms[0].id : ""}
          isLogin={isLogin}
          isAuthLoading={isAuthLoading}
          questions={questions}
          questionTypes={questionTypes}
          cookieUser={user}
          getRoomConfirmedUser={getRoomConfirmedUser}
          signOut={signOut}
        />
      )
    },
    {
      path: "/rooms/:roomID",
      element: (
        <Room
          isLogin={isLogin}
          isAuthLoading={isAuthLoading}
          isConfirmed={isConfirmed}
          questions={questions}
          questionTypes={questionTypes}
          getRoomConfirmedUser={getRoomConfirmedUser}
          user={user}
          signOut={signOut}
          rooms={rooms}
        />
      )
    },
    {
      path: "/rooms/:roomID/invite",
      element: (
        <InviteUser
          rooms={rooms}
          currentUserID={user.id}
          signUpAuth={signUpAuth}
          isAuthLoading={isAuthLoading}
          isLogin={isLogin}
          signOut={signOut}
        />
      )
    },
    {
      path: "/rooms/:roomID/inviteRegistered",
      element: (
        <InviteRegisteredUser
          isLogin={isLogin}
          isAuthLoading={isAuthLoading}
          rooms={rooms}
          currentUserID={user.id}
          signOut={signOut}
        />
      )
    },
    {
      path: "/users/update",
      element: (
        <UpdateUser
          isLogin={isLogin}
          isAuthLoading={isAuthLoading}
          updateUser={updateUser}
          cookieUser={user}
          signOut={signOut}
        />
      )
    }
  ]);

  useEffect(() => {
    async function doGet() {
        if (!isLogin || !isConfirmed) return;
        const result = await fetchGetQuestions();
        setQuestions(result.data);
    }
    doGet();
  }, [isLogin, isConfirmed]);

  useEffect(() => {
    async function doGet() {
        if (!isLogin || !isConfirmed) return;
        const result = await fetchQuestionTypes();
        setQuestionTypes(result.data);
    }
    doGet();
  }, [isLogin, isConfirmed]);

  return (
    <RouterProvider router={router}/>
  );
}

export default App;
