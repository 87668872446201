export class QueryBuilder {
    private query: any;
    constructor() {
        this.query = {}
    }
    setEmail(email: string) {
        this.query.email = email;
    }
    setFullName(fullName: string) {
        this.query.fullName = fullName;
    }
    setTitle(title: string) {
        this.query.title = title;
    }
    setContent(content: string) {
        this.query.content = content;
    }
    build() {
        return JSON.stringify(this.query);
    }
}