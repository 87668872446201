import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router";

import { Question } from "../../../fetch/questions/getAll/entity";
import { QuestionType } from "../../../fetch/questionTypes/getAll/entity";
import { fetchGetRoom } from "../../../fetch/rooms/get";
import { Room as RoomType } from "../../../fetch/rooms/get/entity";
import { RoomUserEntity } from "../../../fetch/users/getRoomUser/entity";
import { User } from "../../../fetch/users/getRoomUser/entity";
import { User as CookieUser } from "../../../fetch/users/cookieUser/entity";

import Messenger from "../../organisms/Messenger/component";
import { Link } from "react-router-dom";
import MessageInput from "../../organisms/MessageInput/component";
import { Button } from "@mui/material";
import { Room as ConfirmedRoomType } from "../../../fetch/rooms/getConfirmed/entity";

import { useMessage } from "../../../hooks/useMessage";

import Header from "../../organisms/Header/component";

import "./style.css";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
    isConfirmed: boolean;
    questions: Question[];
    questionTypes: QuestionType[];
    user: CookieUser;
    rooms: ConfirmedRoomType[];
    getRoomConfirmedUser: (roomID: string) => Promise<RoomUserEntity>;
    signOut: () => Promise<boolean>;
}

const Room: React.FC<Props> = ({
    isLogin,
    isAuthLoading,
    isConfirmed,
    questions,
    questionTypes,
    user,
    rooms,
    getRoomConfirmedUser,
    signOut
}) => {
    const { roomID } = useParams<{roomID: string}>();
    const [room, setRoom] = useState<RoomType>({} as RoomType);
    const [users, setUsers] = useState<User[]>([]);

    const { messages, isLoading: isMessageLoading, error,
    getRoomMessage, createMessage, createImageMessage, createQuestionMessage, updateMessage }
    = useMessage(isLogin, isConfirmed);

    useEffect(() => {
        async function doGet() {
            if (!roomID) return;
            const result = await fetchGetRoom(roomID);
            setRoom(result.data);
        }
        doGet();
    }, [isLogin, isConfirmed, roomID]);

    useEffect(() => {
        if (!roomID) return;
        getRoomMessage(roomID);
    }, [isLogin, isConfirmed, roomID])

    useEffect(() => {
        async function doGet() {
            if (!roomID) return;
            const result = await getRoomConfirmedUser(roomID);
            setUsers(result.data);
        }
        doGet();
    }, [isLogin, isConfirmed, roomID]);
    if (!isLogin && !isAuthLoading) return <Navigate to="/users/sign_in"/>
    if (!isConfirmed || !rooms.length) return <Navigate to="/users/followup"/>
    if (!rooms.map((r) => r.id).includes(roomID || "")) return <Navigate to="/mypage"/>
    return (
        <div className="Room">
            <Header isLogin={isLogin} isAuthLoading={isAuthLoading} signOut={signOut}/>
            <div className="Room__main">
                <Link to="/mypage">トップに戻る</Link>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ml: 1}}
                    onClick={() =>{
                        if (!roomID) return;
                        getRoomMessage(roomID);
                        const messenger = document.getElementsByClassName("Messenger")[0];
                        setTimeout(() => messenger.scroll(0, messenger.scrollHeight), 300);
                    }}
                >
                    更新する
                </Button>
                <div className="Room__title">
                    <img src={room.roomThumbnailImage} width="30" height="30" alt={room.roomTitle} />
                    {room.roomTitle}
                    ({users.length}人)
                </div>
                <br/>
                <Messenger
                    messages={messages}
                    questions={questions}
                    user={user}
                    confirmedUsers={users}
                    isMessageLoading={isMessageLoading}
                    updateMessage={updateMessage}
                    roomID={room.id}
                />
                <p>
                    ※：長い文章を送るときは、あらかじめ word などで作成してから貼り付けた方がいいかもしれません。
                    <br/>
                    ※：１つメッセージに文章は65535文字以上は入りません。
                    <br/>
                    ※：事業承継の知見は100項目以上あるので、最初は基本情報にある23の話題から始めることをオススメします。M&Aで高く会社を売りたい場合などは、「DD」の項目も埋めた方がいいかもしれません。
                </p>
                <MessageInput
                    questions={questions}
                    questionTypes={questionTypes}
                    roomID={room.id}
                    user={user}
                    createMessage={createMessage}
                    createImageMessage={createImageMessage}
                    createQuestionMessage={createQuestionMessage}
                />
            </div>
        </div>
    )
}

export default Room;