import AWS from "aws-sdk"
import React, { useState, useEffect } from "react";
import { Navigate } from "react-router";
import { Button, TextField } from "@mui/material";

import { User } from "../../../fetch/users/cookieUser/entity";
import { fetchCreateCsrf } from "../../../fetch/csrf/create";

import Header from "../../organisms/Header/component";
import { isPictureFile } from "../../../lib/helper/imegaHelper";

import "./style.css";

type Props = {
    isAuthLoading: boolean;
    isLogin: boolean;
    cookieUser: User;
    updateUser: (fullName: string, thumbnailImage: string, fontSize: number, csrf: string) => Promise<boolean>;
    signOut: () => Promise<boolean>;
}

type FormProps = {
    fullName: string;
    thumbnailImage: string;
}

const bucketName = "65diary-image";
const bucketRegion = "us-west-1"
const identityPoolID = "us-west-1:0d55780b-9636-4114-bfcf-b22c1f6d88df";

const UpdateUser: React.FC<Props> = ({
    isAuthLoading,
    isLogin,
    updateUser,
    cookieUser,
    signOut
}) => {
    const [user, setUser] = useState<FormProps>({
        fullName: cookieUser.fullName,
        thumbnailImage: cookieUser.thumbnailImage
    } as FormProps);
    const [csrf, setCsrf] = useState<string>("");
    const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
    const [isUpdateCompleted, setIsUpdateCompleted] = useState<boolean>(false);

    AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolID
        })
    });
    const s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: bucketName }
    });

    const onChangeForm = (type: "fullName" | "thumbnailImage", value: string) => {
        setUser((prev) => {
            return { ...prev, [type]:value }
        });
    }
    const onChangeThumbnailImageFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsImageLoading(true);
        const files = e.target.files;
       if (!files){
        setIsImageLoading(false);
        return;
       }
       const file = files[0];
       if (!isPictureFile(file.name)) {
        setIsImageLoading(false);
        return;
       }
       const randomNumber = Math.floor(Math.random() * 1000000);
       const photoKey = encodeURIComponent("images") + "/" + String(Date.now()) + String(randomNumber) + "_" + file.name;
       const upload = new AWS.S3.ManagedUpload({
        params: {
            Bucket: bucketName,
            Key: photoKey,
            Body: file,
        }
       });
       const promise = upload.promise();
       promise.then((_) => {
        const url = `https://65diary-image.s3.us-west-1.amazonaws.com/${photoKey}`
        console.log("success : ", url);
        setIsImageLoading(false);
        onChangeForm("thumbnailImage", url);
       }).catch((err) => {
        setIsImageLoading(false);
        console.log("error", err);
       })
    }

    const onClickUpdate = async () => {
        if (!csrf || !user.fullName) return;
        const result = await updateUser(user.fullName, user.thumbnailImage, 5, csrf);
        if (result) setIsUpdateCompleted(true);
    }

    useEffect(() => {
        async function doGet() {
            if (isAuthLoading || !isLogin) return;
            const result = await fetchCreateCsrf();
            setCsrf(result.data.validator);
        }
        doGet();
    }, [isLogin, isAuthLoading]);

    if (!isLogin && !isAuthLoading) return <Navigate to="/users/sign_in"/>
    if (isUpdateCompleted) return <Navigate to="/mypage"/>

    return (
        <div className="UpdateUser">
            <Header isAuthLoading={isAuthLoading} isLogin={isLogin} signOut={signOut}/>
            <div className="UpdateUser__main">
                <h3>ユーザーを更新する</h3>
                <p>お名前</p>
                <TextField
                    color="warning"
                    variant="outlined"
                    className="UpdateUser__form"
                    value={user.fullName}
                    onChange={(e) => onChangeForm("fullName", e.target.value)}
                />
                <p>サムネイル画像</p>
                <input type="file" className="UpdateUser__form" onChange={(e) => onChangeThumbnailImageFile(e)} />
                { user.thumbnailImage
                    ? <img src={user.thumbnailImage} width="100" height="auto" />
                    : <>画像は登録されていません</>
                }
                <br/><br/>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isImageLoading}
                    onClick={onClickUpdate}
                >
                    更新する
                </Button>
            </div>
        </div>
    )
}

export default UpdateUser;