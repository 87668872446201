export type QuestionType = {
    id: number;
    title: string;
    userType: number;
    createdAt: string;
}

export class GetQuestionTypeEntity {
    data: QuestionType[];
    error?: string;
    constructor(data: QuestionType[], error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

export class GetQuestionTypeEmpty extends GetQuestionTypeEntity {
    constructor() {
        super([]);
    }
}

export class GetQuestionTypeError extends GetQuestionTypeEntity {
    constructor(errorMessage: string) {
        super([], errorMessage)
    }
}