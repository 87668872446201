export type Prompt = {
    response: string;
}

export class GetPromptEntity {
    data: Prompt;
    error?: string;
    constructor(data: Prompt, error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

export class GetPromptEmpty extends GetPromptEntity {
    constructor() {
        super({response: ""})
    }
}

export class GetPromptError extends GetPromptEntity {
    constructor(errorString: string) {
        super({response: ""}, errorString)
    }
}