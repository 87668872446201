import { ResponseMapper } from "./responseMapper";
import { SignUpError, SignUpEntity } from "./entity";
import { fetchUrl } from "../../../lib/const/url";

export async function fetchSignUpUser(query: any): Promise<SignUpEntity> {
    try {
        const response = await fetch(`${fetchUrl}/users/sign_up`, {
            method: "POST",
            body: query,
            credentials: "include"
        }).then((data) => data.json());
        if (response.message) {
            const result = new SignUpError(response.message);
            return result;
        }
        const entity = new ResponseMapper(response).convert();
        return entity;
    } catch (e) {
        console.log(e);
        return new SignUpError(JSON.stringify(e));
    }
}