import { fetchUrl } from "../../../lib/const/url";
import { CreateOpinionEntity, CreateOpinionError } from "./entity";


export async function fetchCreateOpinion(query: any) {
    try {
        const result = await fetch(`${fetchUrl}/opinions/create`, {
            method: "POST",
            body: query,
            credentials: "include"
        }).then((data) => data.json());
        if (result.message) {
            return new CreateOpinionError(result.message);
        }
        return new CreateOpinionEntity({status: result.Status});
    } catch (e) {
        console.log(e);
        return new CreateOpinionError(JSON.stringify(e))
    }
}