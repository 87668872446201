import React, {useState} from "react";
import { Link, Navigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";

import "./style.css";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
    signOut?: () => Promise<boolean>;
}

const Header: React.FC<Props> = ({isLogin, isAuthLoading, signOut}) => {
    const [isLogout, setIsLogout] = useState<boolean>(false);
    const onClickLogout = () => {
        signOut && signOut();
        setIsLogout(true);
    }
    if (isLogout) return <Navigate to="/"/>
    return(
    <AppBar className="Header" component="header" position="static" color="warning">
        <Container maxWidth="lg">
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <Box>
                    <Typography sx={{ my: 2 }} component="h1">
                        <Link className="Header__mainTitle" to={ isLogin ? "/" : "/"}>事業承継サポート</Link>
                    </Typography>
                </Box>
                <Box sx={{display: "flex"}}>
                    {
                    isAuthLoading ?
                        <CircularProgress color="info" />
                    :
                    isLogin
                    ?
                        <>
                            <Box sx={{ my: 2, pl: 2 }}>
                                <Link className="Header__subTitle" to="/mypage">マイページ</Link>
                            </Box>
                            <Box sx={{ my: 2, pl: 2 }}>
                                <Link className="Header__subTitle" to="/usage">使い方を見る</Link>
                            </Box>
                            <Box sx={{ my: 2, pl: 2 }}>
                                <Link className="Header__subTitle" to="/users/update">プロフィール編集</Link>
                            </Box>
                            <Box sx={{ my: 2, pl: 2 }}>
                                <Link className="Header__subTitle" to="/opinions">ご意見</Link>
                            </Box>
                            { signOut &&
                                <Box sx={{ my: 2, pl: 2 }} onClick={onClickLogout}>
                                    ログアウト
                                </Box>
                            }
                        </>
                    :
                        <>
                            <Box sx={{ my: 2, pl: 2 }}>
                                <Link className="Header__subTitle" to="/usage">使い方を見る</Link>
                            </Box>
                            <Box sx={{ my: 2, pl: 2 }}>
                                <Link className="Header__subTitle" to="/opinions">ご意見</Link>
                            </Box>
                            <Box sx={{ my: 2, pl: 2 }}>
                                <Link className="Header__subTitle" to="/users/sign_up">会員登録</Link>
                            </Box>
                            <Box sx={{ my: 2, pl: 2 }}>
                                <Link className="Header__subTitle" to="/users/sign_in">ログイン</Link>
                            </Box>
                        </>
                    }
                </Box>
            </Box>
        </Container>
    </AppBar>
    )
}

export default Header;