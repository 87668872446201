import { GetQuestionTypeEntity, GetQuestionTypeError } from "./entity";
import { ResponseMapper } from "./responseMapper";
import { fetchUrl } from "../../../lib/const/url";

export async function fetchQuestionTypes(): Promise<GetQuestionTypeEntity> {
    try {
        const response = await fetch(`${fetchUrl}/questionTypes/all`, {
            method: "GET",
            credentials: "include"
        }).then((data) => data.json());
        if (response.message) {
            return new GetQuestionTypeError(response.message);
        }
        const entity = new ResponseMapper(response).convert();
        return entity;
    } catch (e) {
        console.log(e);
        return new GetQuestionTypeError(JSON.stringify(e));
    }
}