import React from "react";
import PersonIcon from '@mui/icons-material/Person';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SchoolIcon from '@mui/icons-material/School';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import Header from "../../organisms/Header/component";

import "./style.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
}

const Usage: React.FC<Props> = ({
    isAuthLoading,
    isLogin
}) => {
    return (
    <div className="Usage">
        <Header isAuthLoading={isAuthLoading} isLogin={isLogin}/>
        <div className="Usage__main">
            <h1>使い方のステップ</h1>
            <hr color="warning" />
            <p style={{lineHeight: 1.5}}>
                このページは、普段は話しづらい事業継承についてチャット形式で話せるWebサイトです。
                <br/>
                使い方は、以下の３手順です。
            </p>
            <div className="Usage__step">
                <h3 className="Usage__title">Step1</h3>
                <PersonIcon className="Usage__icon"/>
                <h4 className="Usage__subTitle">ユーザー登録</h4>
            </div>
            <div className="Usage__step">
                <h3 className="Usage__title">Step2</h3>
                <MeetingRoomIcon className="Usage__icon"/>
                <h4 className="Usage__subTitle">部屋を作成 ＆ 他ユーザーを招待</h4>
            </div>
            <div className="Usage__step">
                <h3 className="Usage__title">Step3</h3>
                <QuestionAnswerIcon className="Usage__icon"/>
                <h4 className="Usage__subTitle">部屋内で、事業継承に関する知見について話しましょう！</h4>
                <br/>
                <Accordion defaultExpanded>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                    <Typography>どうやって部屋に入るの？</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography className="Usage__text">
                        １：上にあるバナーの「マイページ」をクリックします
                        <br/>
                        ２：上の方にある「部屋」をクリックすれば部屋に移動できます
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    >
                    <Typography>どうやって部屋でチャットするの？</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography className="Usage__text">
                        部屋に入ったら、下の方の「普通のメッセージ」「事業継承に関する知見のメッセージ」「画像のメッセージ」をクリックします。
                        <br/>
                        それぞれにクリックした場合の方法を説明します。
                        <hr/>
                        <Button size="small" variant="contained" sx={{my: 1}}>普通のメッセージ</Button>
                        <br/>
                        「メッセージを入力してください」の欄にメッセージを入力して、「メッセージを作成する」を押します
                        <hr/>
                        <Button size="small" variant="contained" sx={{my: 1}}>事業継承に関する知見のメッセージ</Button>
                        <br/>
                        ・ポップアップが出てくるので、そこからチャットしたい事業継承に関する知見を探して、「選択する」をクリックします
                        <br/>
                        ・「メッセージを入力してください」の欄にメッセージを入力して、「メッセージを作成する」を押します
                        <hr/>
                        <Button size="small" variant="contained" sx={{my: 1}}>画像のメッセージ</Button>
                        <br/>
                        ・「メッセージを入力してください」の欄にメッセージを入力して、その下の「選択」をクリックしてアップロードしたい画像を選択します
                        <br/>
                        「メッセージを作成する」を押します
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                    >
                    <Typography>話せる事業継承に関する知見は何があるの？</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography className="Usage__text">
                       上にあるバナーの「マイページ」をクリックして遷移する、マイページの下の方に事業継承に関する知見が検索できる場所があります。
                       <br/>
                       もしくは、<Link to="/questionsAll">こちら</Link>を参照にしてください。
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                    >
                    <Typography>どうやってChatGPTに経営指南を受けられるの？</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography className="Usage__text">
                       まずは、部屋の中で「事業継承に関する知見」から事業承継に関するメッセージを投稿してください。
                       <br/>
                       次に、部屋の中で投稿された画面の「続きを見る」からメッセージ詳細に遷移し、下の方にある「ChatGPTの意見を聞く」をクリックすればChatGPTの意見が聞けます。
                    </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
            <br/>
            <h1>他のチャットアプリより何がいいか？</h1>
            <hr color="warning"/>
            <br/>
            <div className="Usage__step">
                <h3 className="Usage__title">1</h3>
                <QuestionMarkIcon className="Usage__icon"/>
                <h4 className="Usage__subTitle">事業承継に関して何をまとめればいいか分かる</h4>
                <p className="usage_smallTitle">事業継承に関する知見が書いてあるので、１つ１つ知識を残しましょう</p>
            </div>
            <div className="Usage__step">
                <h3 className="Usage__title">2</h3>
                <SchoolIcon className="Usage__icon"/>
                <h4 className="Usage__subTitle">事業承継専門の場なので、きっかけ作りとして話やすい</h4>
            </div>
            <div className="Usage__step">
                <h3 className="Usage__title">3</h3>
                <AutoStoriesIcon className="Usage__icon"/>
                <h4 className="Usage__subTitle">ChatGPTに自分が書いた事業承継に関する会話に対する意見をもらえる</h4>
                <p className="usage_smallTitle">事業継承に関する知見ごとで知識を検索できます</p>
            </div>
            <Link to="/questionsAll">話せる事業継承に関する知見一覧はこちら</Link>
            <br/><br/>
            <h1>使い方</h1>
            <hr color="warning"/>
            <br/>
            <div className="Usage__step">
                <h3 className="Usage__title">Step1</h3>
                <img src="https://65diary-image.s3.us-west-1.amazonaws.com/images/65diary_explain_register1.png" width="80%" alt="登録する" className="Usage__image"/>
                <h4 className="Usage__subTitle"><Link to="/users/sign_in">こちら</Link>からユーザー登録します。</h4>
            </div>
            <div className="Usage__step">
                <h3 className="Usage__title">Step2</h3>
                <img src="https://65diary-image.s3.us-west-1.amazonaws.com/images/65diary_explain_register2.png" width="80%" alt="招待する" className="Usage__image"/>
                <h4 className="Usage__subTitle">事業承継に関係する親戚や親族を招待します（初期設定では最大１０人まで）</h4>
            </div>
            <div className="Usage__step">
                <h3 className="Usage__title">Step3</h3>
                <img src="https://65diary-image.s3.us-west-1.amazonaws.com/images/65diary_explain_register3.png" width="80%" alt="部屋を作る" className="Usage__image"/>
                <h4 className="Usage__subTitle">部屋を作ります</h4>
            </div>
            <div className="Usage__step">
                <h3 className="Usage__title">Step4</h3>
                <img src="https://65diary-image.s3.us-west-1.amazonaws.com/images/65diary_explain_top1.png" width="80%" alt="トップページ１" className="Usage__image"/>
                <h4 className="Usage__subTitle">Step1〜3の登録が完了すると、トップページに遷移します。ここからも招待できます</h4>
            </div>
            <div className="Usage__step">
                <h3 className="Usage__title">Step5</h3>
                <img src="https://65diary-image.s3.us-west-1.amazonaws.com/images/65diary_explain_top2.png" width="80%" alt="トップページ２" className="Usage__image"/>
                <h4 className="Usage__subTitle">トップページの下の方に、話せる事業継承に関する知見一覧・その事業継承に関する知見を話したかどうかが表示されます</h4>
            </div>
            <div className="Usage__step">
                <h3 className="Usage__title">Step6</h3>
                <img src="https://65diary-image.s3.us-west-1.amazonaws.com/images/65diary_explain_message3.png" width="80%" alt="メッセージ" className="Usage__image"/>
                <h4 className="Usage__subTitle">部屋に入れば、メッセージが入力できます。事業承継に関するメッセージも入力できます。<br/>メッセージの詳細からChatGPTの意見も聞けます。</h4>
            </div>
        </div>
    </div>
    )
}

export default Usage;