import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from "react-router-dom";

const MyPageUsage = () => {
    return (
        <div className="MyPageUsage">
            <Accordion variant="outlined">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>本ページは？</AccordionSummary>
                <AccordionDetails>
                    <p className="MyPageUsage__accordionText">
                        このページは、経営者とそのご子息さんたちが、事業承継について話せたり、ChatGPTに経営指南を受けてもらえるSNSです。
                    </p>
                </AccordionDetails>
            </Accordion>
            <br/>
            <Accordion variant="outlined">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>使い方</AccordionSummary>
                <AccordionDetails>
                    <p className="MyPageUsage__accordionText">
                        １：部屋一覧の部屋のドロップダウンをクリックし、見たい部屋をクリックします。
                        <br/>
                        ２：「部屋名：[部屋の名前]」の部分をクリックします。
                        <br/>
                        ３：事業継承に関する知見に沿った内容をチャットしたり、メッセージの詳細から経営に関するアドバイスをChatGPTからもらって、議論を深めましょう！
                        <br/><br/>
                        詳細の使い方は<Link to="/usage">こちら</Link>から見られます。
                    </p>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default MyPageUsage;