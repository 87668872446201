export type Message = {
    id: number;
    userID: string;
    questionID: number;
    imageUrl: string;
    content: string;
    createdAt: string;
}

export class CreateMessageEntity {
    data: Message;
    error?: string;
    constructor(data: Message, error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

const emptyMessage = {
    id: 0,
    userID: "",
    questionID: 0,
    imageUrl: "",
    content: "",
    createdAt: ""
};

export class CreateMessageEmpty extends CreateMessageEntity {
    constructor() {
        super(emptyMessage);
    }
}

export class CreateMessageError extends CreateMessageEntity {
    constructor(errorMessage: string) {
        super(emptyMessage, errorMessage);
    }
}