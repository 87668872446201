import { ConfirmEmailEmpty, ConfirmEmailEntity } from "./entity"

export class ResponseMapper {
    private data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert(): ConfirmEmailEntity {
        return this.data
        ? new ConfirmEmailEntity({
            status: String(this.data?.Status)
        })
        : new ConfirmEmailEmpty();
    }
}