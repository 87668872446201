export class QueryBuilder {
    private query: any;
    constructor() {
        this.query = {};
    }
    setEmail(email: string) {
        this.query.email = email;
    }
    setPassword(password: string) {
        this.query.password = password;
    }
    build() {
        return JSON.stringify(this.query);
    }
}