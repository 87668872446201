export type Status = {
    status: string;
}

export class InviteEntity {
    data: Status;
    error?: string;
    constructor(data: Status, error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

export class InviteEmpty extends InviteEntity {
    constructor() {
        super({status: "unknown"});
    }
}

export class InviteError extends InviteEntity {
    constructor(errorString: string) {
        super({status: "fail"}, errorString);
    }
}