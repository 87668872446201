import { CreateRoomEntity, CreateRoomEmpty } from "./entity";

export class ResponseMapper {
    private data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert(): CreateRoomEntity {
        return this.data
        ? new CreateRoomEntity({
            id: String(this.data?.ID),
            title: String(this.data?.Title),
            thumbnailImage: String(this.data?.ThumbnailImage),
            ownerID: String(this.data?.ownerID)
        })
        : new CreateRoomEmpty();
    }
}