import { UpdateRoomEntity, UpdateRoomError } from "./entity"
import { ResponseMapper } from "./responseMapper"
import { fetchUrl } from "../../../lib/const/url"

export async function fetchUpdateRoom(roomID: string, query: any): Promise<UpdateRoomEntity> {
    try {
        const response = await fetch(`${fetchUrl}/rooms/${roomID}/update`, {
            method: "POST",
            body: query,
            credentials: "include"
        }).then((data) => data.json());
        if (response.message) {
            return new UpdateRoomError(response.message);
        }
        const entity = new ResponseMapper(response).convert();
        return entity;
    } catch (e) {
        console.log(e);
        return new UpdateRoomError(JSON.stringify(e));
    }
}