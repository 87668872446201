import { UpdateEntity, UpdateError } from "./entity";
import { ResponseMapper } from "./responseMapper";
import { fetchUrl } from "../../../lib/const/url";

export async function fetchUpdateUser(query: any): Promise<UpdateEntity> {
    try {
        const response = await fetch(`${fetchUrl}/auth_users/update`, {
            method: "POST",
            body: query,
            credentials: "include"
        }).then((data) => data.json());
        if (response.message) {
            return new UpdateError(response.message);
        }
        const entity = new ResponseMapper(response).convert();
        return entity;
    } catch (e) {
        console.log(e);
        return new UpdateError(JSON.stringify(e));
    }
}