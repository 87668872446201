export type Prompt = {
    response: string;
}

export class CreatePromptEntity {
    data: Prompt;
    error?: string;
    constructor(data: Prompt, error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

export class CreatePromptEmpty extends CreatePromptEntity {
    constructor() {
        super({response: ""})
    }
}

export class CreatePromptError extends CreatePromptEntity {
    constructor(errorString: string) {
        super({response: ""}, errorString)
    }
}