import { fetchUrl } from "../../../lib/const/url";
import { GetPromptEmpty, GetPromptEntity, GetPromptError } from "./entity";

export async function fetchGetPrompt(query: any) {
    try {
        const response = await fetch(`${fetchUrl}/prompts/get`, {
            method: "POST",
            body: query,
            credentials: "include"
        }).then((data) => data.json());
        if (!response.Response) return new GetPromptEmpty();
        return new GetPromptEntity({response: response.Response});
    } catch(e) {
        console.log(e);
        return new GetPromptError(JSON.stringify(e));
    }
}