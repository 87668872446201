import React, {useState, useEffect} from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";

import { Room } from "../../../fetch/rooms/getConfirmed/entity";
import { RoomUserEntity, User } from "../../../fetch/users/getRoomUser/entity";
import { fetchGetRoomMessage } from "../../../fetch/messages/getRoom";
import { QueryBuilder as RoomMessageQueryBuilder } from "../../../fetch/messages/getRoom/queryBuilder";
import { Message } from "../../../fetch/messages/getRoom/entity";
import { QueryBuilder as RoomQuestionMessageQueryBuilder } from "../../../fetch/messages/getRoomQuestion/queryBuilder";

import Header from "../../organisms/Header/component";
import MyPageUsage from "../../organisms/MyPageUsage/component";

import "./style.css";
import { fetchRoomQuestionMessage } from "../../../fetch/messages/getRoomQuestion";
import { Question } from "../../../fetch/questions/getAll/entity";
import { QuestionType } from "../../../fetch/questionTypes/getAll/entity";
import QuestionFilter from "../../organisms/QuestionFilter/component";
import { defaultUserUrl } from "../../../lib/const/url";
import { Button, MenuItem, Select } from "@mui/material";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
    isConfirmed: boolean;
    rooms: Room[];
    getRoomConfirmedUser: (roomID: string) => Promise<RoomUserEntity>;
    questions: Question[];
    questionTypes: QuestionType[];
    currentUserID: string;
    signOut: () => Promise<boolean>;
};

const MINIMUM_MESSAGE_COUNT = 5;

const MyPage: React.FC<Props> = ({
    isLogin,
    isAuthLoading,
    isConfirmed,
    rooms,
    getRoomConfirmedUser,
    questions,
    questionTypes,
    currentUserID,
    signOut
}) => {
    const [roomUsers, setRoomUsers] = useState<User[]>([]);
    const [roomMessages, setRoomMessages] = useState<Message[]>([]);
    const [roomQuestionMessages, setRoomQuestionMessages] = useState<Message[]>([]);
    const [currentRoomID, setCurrentRoomID] = useState<string>("");
    const room = rooms.find((r) => r.id === currentRoomID);
    useEffect(() => {
        async function doGet() {
            if (!rooms.length) return;
            if (!currentRoomID) return;
            const result = await getRoomConfirmedUser(currentRoomID);
            setRoomUsers(result.data);
        }
        doGet();
    }, [rooms, currentRoomID]);
    useEffect(() => {
        async function doGet() {
            if (!rooms.length) return;
            if (!currentRoomID) return;
            const queryBuilder = new RoomMessageQueryBuilder();
            queryBuilder.setRoomID(currentRoomID);
            const result = await fetchGetRoomMessage(queryBuilder.build());
            setRoomMessages(result.data);
        }
        doGet();
    }, [rooms, currentRoomID]);
    useEffect(() => {
        async function doGet() {
            if (!rooms.length) return;
            if (!currentRoomID) return;
            const queryBuilder = new RoomQuestionMessageQueryBuilder();
            queryBuilder.setRoomID(currentRoomID);
            const result = await fetchRoomQuestionMessage(queryBuilder.build());
            setRoomQuestionMessages(result.data);
        }
        doGet();
    }, [rooms, currentRoomID]);
    if (!isLogin && !isAuthLoading) return <Navigate to="/users/sign_in"/>
    if (!isConfirmed || !rooms.length) return <Navigate to="/users/followup"/>
    return(
        <div className="MyPage">
            <Header isLogin={isLogin} isAuthLoading={isAuthLoading} signOut={signOut}/>
            <div className="MyPage__main">
                <h3 className="MyPage__title">マイページ</h3>
                { roomMessages.length < MINIMUM_MESSAGE_COUNT && <MyPageUsage/> }
                <h5>あなたのユーザーID：{currentUserID}</h5>
                <div className="MyPage__rooms">
                    <h5>部屋 {!currentRoomID && <small>部屋を選択してください</small>}</h5>
                    <Select
                        value={currentRoomID}
                        onChange={(e) => setCurrentRoomID(e.target.value)}
                        className="MyPage__select"
                    >
                        { rooms.map((room) => {
                            return (
                                <MenuItem key={`room_${room.id}`} value={room.id}>{room.title}</MenuItem>
                            );
                        })}
                    </Select>
                    <br/>
                    { room &&
                        <div key={`room_${room.id}`} className="MyPage__roomArea">
                            <div className="MyPage__room">
                                <Link to={`/rooms/${room.id}`} className="MyPage__link">
                                    <img src={room.thumbnailImage} width="20" height="20" alt={room.title} />
                                    <div>
                                        <span>部屋名：{room.title}</span>
                                        <br/>
                                        <small className="MyPage__roomMessage">{!roomMessages.length ? "まだメッセージはありません" : roomMessages[0].content.slice(0, 15) + "..."} [続きを見る]</small>
                                    </div>
                                </Link>
                            </div>
                            <div className="MyPage__usersArea">
                                <small>部屋のユーザー一覧</small>
                                <div className="MyPage__users">
                                    { roomUsers.map((user) => {
                                        return (
                                            <div className="MyPage__user" key={`user_${user.id}`}>
                                                <img
                                                    className="MyPage__userImage"
                                                    src={user.thumbnailImage || defaultUserUrl}
                                                    width="20"
                                                    height="20"
                                                    alt={user.fullName}
                                                />
                                                <div>
                                                お名前：{user.fullName}
                                                <br/>
                                                <small>{user.userType === 1 ? "親" : user.userType === 2 ? "子供" : "関係者"}</small>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                { rooms.find((room) => room.id === currentRoomID)?.ownerID === currentUserID &&
                                <>
                                    <Button color="warning" variant="contained" sx={{mr: 1, mb: 1}}>
                                        <Link to={`/rooms/${rooms.find((room) => room.id === currentRoomID)?.id}/invite`} className="MyPage__link" style={{color: "white"}}>
                                            未登録ユーザーを招待する
                                        </Link>
                                    </Button>
                                    <Button color="success" variant="contained" sx={{mr: 1, mb: 1}}>
                                        <Link to={`/rooms/${rooms.find((room) => room.id === currentRoomID)?.id}/inviteRegistered`} className="MyPage__link" style={{color: "white"}}>
                                            登録済みユーザーを招待する
                                        </Link>
                                    </Button>
                                </>
                                }
                            </div>
                        </div>
                    }
                    <Button color="primary" variant="contained">
                        <Link to="/users/update" className="MyPage__link" style={{color: "white"}}>
                            自分のユーザー情報を更新する
                        </Link>
                    </Button>
                </div>
                <div className="MyPage__questions">
                    <h5>
                        話す事業継承に関する知見一覧
                        <br/>
                        <small style={{color: "#999"}}>
                            事業承継のテーマは、主に「基本情報」「DD（デューデリジェンス）」「改善」の3種類あります。
                            <br/>
                            最初は、基本情報にある23の話題から始めることをオススメします。
                        </small>
                    </h5>
                    <QuestionFilter
                        questionTypes={questionTypes}
                        questions={questions}
                        questionMessages={roomQuestionMessages}
                    />
                </div>
                <br/>
                { roomMessages.length >= MINIMUM_MESSAGE_COUNT && <MyPageUsage/> }
            </div>
        </div>
    )
}

export default MyPage;