export type User = {
    fullName: string;
    thumbnailImage: string;
    fontSize: number;
    csrf: string;
}

export class UpdateEntity {
    data: any;
    error?: string;
    constructor(data: User, error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

const emptyUser = {
    fullName: "",
    thumbnailImage: "",
    fontSize: 0,
    csrf: ""
}

export class UpdateEmpty extends UpdateEntity{
    constructor() {
        super(emptyUser);
    }
}

export class UpdateError extends UpdateEntity {
    constructor(errorMessage: string) {
        super(emptyUser, errorMessage);
    }
}