import React from "react";
import { Link } from "react-router-dom";

import Header from "../../organisms/Header/component";

import "./style.css";

type Props = {
    isLogin: boolean;
    isAuthLoading: boolean;
}

const Terms: React.FC<Props> = ({isLogin, isAuthLoading}) => {
    return (
        <div className="Terms">
            <Header isLogin={isLogin} isAuthLoading={isAuthLoading}/>
            <div className="Terms__main">
                <h1>利用規約</h1>
                <h3>１：序文</h3>
                <p>本利用規約は、「事業承継サポート」（以下「当サイト」）を利用する際の条件を定めたものです。利用者は、本利用規約に同意した上で当サイトを利用するものとします。</p>
                <h3>２：定義</h3>
                <p>「ユーザー」とは、当サイトを利用する全ての個人または法人を指します。</p>
                <p>「コンテンツ」とは、当サイトに掲載される全てのテキスト、画像、動画、その他の情報を指します。</p>
                <h3>３：サービスの利用</h3>
                <p>当サイトは、ユーザーがマッチングアプリでの会話のレコメンドを受けるためのプラットフォームを提供します。利用者は、当サイトを利用することで、本利用規約に従うことに同意したものとみなされます。</p>
                <h3>４：アカウントの登録情報</h3>
                <p>アカウントを登録したユーザーは、当該アカウントを削除することができません。ご容赦ください。</p>
                <h3>５：ユーザーの責任</h3>
                <p>ユーザーは、自身のアカウント情報の管理とセキュリティに責任を持ちます。また、以下の行為を行わないものとします：</p>
                <ul>
                    <li>不正アクセスやハッキング行為</li>
                    <li>他のユーザーへの迷惑行為や誹謗中傷</li>
                    <li>ChatGPTへ悪意を持つもしくは不正なプロンプトを投げる行為</li>
                </ul>
                <h3>６：ユーザーのデータおよびコンテンツの取扱い</h3>
                <p>ユーザーが当サイトの管理するサーバーに保存しているデータやコンテンツについて、当サイトではバックアップの義務を負わないものとし、ユーザーご自身においてバックアップを行っていただくものとします。</p>
                <p>当サイトは、ユーザーがコンテンツに関し法令もしくは本利用規約に違反し、または違反するおそれがあると認めた場合、その他業務上合理的な必要がある場合、あらかじめユーザーに通知することなく、コンテンツを削除する等の方法により、当サイトの利用を制限できます。</p>
                <p>当サイトは、法令または本利用規約の順守状況等を確認する必要がある場合、法令に反しない限り、ユーザーのコンテンツの内容を確認することができます。ただし、当サイトはそのような確認を行う義務を負うものではありません。</p>
                <h3>７：プライバシー</h3>
                <p>当サイトは、ユーザーの個人情報を適切に取り扱います。詳細は<Link to="/privacy">プライバシーポリシー</Link>をご参照ください。</p>
                <h3>８：当サイトの提供</h3>
                <p>ユーザーは、当社サイトを利用するにあたり、必要なパーソナルコンピュータ、携帯電話機、スマートフォンその他の通信機器、オペレーションシステム、通信手段および電力等を、ユーザーの費用と責任で用意しなければなりません。</p>
                <p>当サイトは、以下のいずれかに該当する場合、当サイトの全部または一部の提供を一時的に停止することができます。</p>
                <ul>
                    <li>システムのメンテナンスまたは修理の実施を行う場合</li>
                    <li>火災・停電等の事故、天災、戦争、暴動、労働争議等の不可抗力により、サービスの提供ができなくなった場合</li>
                    <li>システムの障害が発生した場合またはシステムに負荷が集中した場合</li>
                    <li>ユーザーまたは第三者の安全を確保する場合または公共の利益のために緊急を要する場合</li>
                    <li>上記に定めるもののほか、当サイトが必要と合理的に判断した場合</li>
                </ul>
                <h3>９：知的財産</h3>
                <p>当サイトに掲載される全てのコンテンツの著作権は、当サイトまたは正当な権利者に帰属します。</p>
                <h3>１０：保証の否認</h3>
                <p>当サイトは、提供する情報やサービスの正確性、完全性、信頼性について保証しません。ユーザーは自己責任で当サイトを利用するものとします。</p>
                <h3>１１：責任の制限</h3>
                <p>当サイトは、直接的または間接的に生じた損害について、一切の責任を負わないものとします。</p>
                <h3>１２：当サイト利用にあたっての順守事項</h3>
                <p>当サイトのご利用に際しては、以下に定める行為を禁止いたします。</p>
                <ul>
                    <li>法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為</li>
                    <li>社会規範・公序良俗に反する行為や、当サイトもしくは第三者の著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、その他法令上もしくは契約上の権利を侵害し、または他人の迷惑となる行為</li>
                    <li>過度に暴力的な表現、露骨な性的表現、児童ポルノ・児童虐待に相当する表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引または助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を、投稿等する行為</li>
                    <li>他のユーザーの使用するソフトウェア、ハードウェア等の機能を破壊したり、妨害したりするようなプログラム等の投稿等をする行為</li>
                    <li>当サイトのサーバーまたはネットワークの機能を破壊したり、妨害したりする行為、BOT、チートツール、その他の技術的手段を利用して当サイトを不正に操作する行為、当サイトの不具合を意図的に利用する行為、同様の質問を必要以上に繰り返す等、当サイトに対し不当な問い合わせまたは要求をする行為、その他当サイトの運営または他のユーザーによる当サイトの利用を妨害し、これらに支障を与える行為</li>
                    <li>不当な目的または態様でのリバースエンジニアリング、逆アセンブルを行うこと、また、その他の方法でソースコードを解読すること</li>
                    <li>個人情報、登録情報、利用履歴情報等の第三者に関する情報を不正に収集、蓄積したり、開示、提供したりする行為</li>
                    <li>他のユーザーのアカウントを使用して当サイトを利用する行為</li>
                    <li>当サイトまたは第三者になりすます行為または意図的に虚偽の情報を流布させる行為</li>
                    <li>手段のいかんを問わず他人からアカウントやパスワードを入手したり、他人にアカウントやパスワードを開示したり提供したりする行為</li>
                    <li>反社会的勢力に直接・間接に利益を提供する行為その他の反社会的勢力に対する協力行為</li>
                    <li>上記に定めるもののほか、当サイトが不適当と合理的に判断した行為</li>
                </ul>
                <h3>１３：免責事項</h3>
                <p>当サイトの責任は、その性質を問わず、当サイトの故意または重過失によらない場合には免責されるものとします。</p>
                <h3>１４：サービスの変更と終了</h3>
                <p>当サイトは、ユーザーに事前通知することなく、サービス内容や利用規約を変更する権利を有します。また、サービスを終了することがあります。</p>
                <h3>１５：利用規約の変更について</h3>
                <p>当サイトは、以下の各号のいずれかの場合には、本利用規約を変更することができるものとします。</p>
                <ul>
                    <li>本利用規約の変更が、ユーザーの一般の利益に適合するとき</li>
                    <li>本利用規約の変更が、契約の目的に反するものではなく、かつ変更の必要性、変更後の内容の相当性、その他の変更に係る事情に照らして合理性があるとき</li>
                </ul>
                <h3>１６：準拠法と裁判管轄</h3>
                <p>本利用規約は、日本法に準拠し、解釈されるものとします。紛争が生じた場合、裁判管轄地の裁判所(東京地方裁判所)を専属的合意管轄とします。</p>
                <h3>１７：連絡先情報</h3>
                <p>メール：<a href="mailto:support@65diary.jp">support@65diary.jp</a></p>
            </div>
        </div>
    )
}

export default Terms;