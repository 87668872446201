import { UpdateRoomEntity, UpdateRoomEmpty } from "./entity"

export class ResponseMapper {
    private data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert(): UpdateRoomEntity {
        return this.data
        ? new UpdateRoomEntity({
            id: String(this.data?.ID),
            title: String(this.data?.Title),
            thumbnailImage: String(this.data?.ThumbnailImage)
        })
        : new UpdateRoomEmpty();
    }
}
