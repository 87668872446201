import { toInt } from "../../../lib/helper/valueCast";
import { RoomUserEntity, RoomUserEmpty } from "./entity";

export class ResponseMapper {
    private data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert(): RoomUserEntity {
        return this.data.Users && Array.isArray(this.data.Users)
        ? new RoomUserEntity(
            this.data.Users.map((d: any) => ({
                id: String(d?.ID),
                fullName: String(d?.FullName),
                userType: toInt(d?.UserType),
                thumbnailImage: String(d?.ThumbnailImage),
                isEmailConfirmed: Boolean(d?.IsEmailConfirmed),
                createdAt: String(d?.CreatedAt)
            }))
        )
        : new RoomUserEmpty();
    }
}