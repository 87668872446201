export type Room = {
    id: string;
    title: string;
    thumbnailImage: string;
}

export class UpdateRoomEntity {
    data: Room;
    error?: string;
    constructor(data: Room, error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

const emptyRoom = {
    id: "",
    title: "",
    thumbnailImage: ""
}

export class UpdateRoomEmpty extends UpdateRoomEntity {
    constructor() {
        super(emptyRoom);
    }
}

export class UpdateRoomError extends UpdateRoomEntity {
    constructor(errorMessage: string) {
        super(emptyRoom, errorMessage);
    }
}