import { CreateImageMessageEntity, CreateImageMessageError } from "./entity";
import { ResponseMapper } from "./responseMapper";
import { fetchUrl } from "../../../lib/const/url";

export async function fetchCreateImageMessage(query: any): Promise<CreateImageMessageEntity>{
    try {
        const response = await fetch(`${fetchUrl}/messages/create/images`, {
            method: "POST",
            body: query,
            credentials: "include"
        }).then((data) => data.json());
        if (response.message) {
            return new CreateImageMessageError(response.message);
        }
        const entity = new ResponseMapper(response).convert();
        return entity;
    } catch(e) {
        console.log(e);
        return new CreateImageMessageError(JSON.stringify(e));
    }
}