export type User = {
    id: string;
    fullName: string;
    userType: number;
    thumbnailImage: string;
    isEmailConfirmed: boolean;
    createdAt: string;
}

export class RoomUserEntity {
    data: User[];
    error?: string;
    constructor(data: User[], error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

export class RoomUserEmpty extends RoomUserEntity {
    constructor() {
        super([]);
    }
}

export class RoomUserError extends RoomUserEntity {
    constructor(errorMessage: string) {
        super([], errorMessage);
    }
}