import React, { useState, useEffect } from "react";

// sign_in
import { QueryBuilder as SignInQueryBuilder } from "../fetch/users/signIn/queryBuilder";
import { fetchSignInUser } from "../fetch/users/signIn";
// sign_up
import { QueryBuilder as SignUpQueryBuilder } from "../fetch/users/signUp/queryBuilder";
import { fetchSignUpUser } from "../fetch/users/signUp";
// sign_up_auth
import { QueryBuilder as SignUpAuthQueryBuilder } from "../fetch/users/signUpAuth/queryBuilder";
import { fetchAuthSignUpUser } from "../fetch/users/signUpAuth";
// sign_out
import { fetchSignOutUser } from "../fetch/users/signOut";
// confirm
import { fetchConfirmEmailUser } from "../fetch/users/confirmEmail";
// update
import { QueryBuilder as UpdateUserQueryBuilder } from "../fetch/users/update/queryBuilder";
import { fetchUpdateUser } from "../fetch/users/update";
// cookieUser
import { User } from "../fetch/users/cookieUser/entity";
import { fetchCookieUser } from "../fetch/users/cookieUser";
import { SignUpEmpty, SignUpEntity } from "../fetch/users/signUpAuth/entity";
// roomConfirmedUser
import { fetchRoomUser } from "../fetch/users/getRoomUser";
import { QueryBuilder as GetRoomUserQueryBuilder } from "../fetch/users/getRoomUser/queryBuilder";
import { RoomUserEmpty, RoomUserEntity } from "../fetch/users/getRoomUser/entity";

export const useUser = () => {
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [user, setUser] = useState<User>({} as User);
    const [error, setError] = useState<string | null>(null);

    const cookieSignIn = async () => {
        if (isLogin) return;
        setIsLoading(true);
        const result = await fetchCookieUser();
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return;
        }
        setIsLoading(false);
        setIsLogin(true);
        setIsConfirmed(result.data.isEmailConfirmed);
        setUser(result.data);
    }

    const signIn = async (email: string, password: string): Promise<boolean> => {
        if (isLogin) return true;
        setIsLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 500));
        const queryBuilder = new SignInQueryBuilder();
        queryBuilder.setEmail(email);
        queryBuilder.setPassword(password);
        const result = await fetchSignInUser(queryBuilder.build());
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return false;
        }
        setIsLoading(false);
        setIsLogin(true);
        setIsConfirmed(result.data.isEmailConfirmed);
        setUser(result.data);
        return true;
    }

    const signUpAuth = async(email: string, password: string, fullName: string, userType: number): Promise<SignUpEntity> => {
        if (!isLogin) return new SignUpEmpty();
        setIsLoading(true);
        const queryBuilder = new SignUpAuthQueryBuilder();
        queryBuilder.setEmail(email);
        queryBuilder.setPassword(password);
        queryBuilder.setFullName(fullName);
        queryBuilder.setUserType(userType);
        const result = await fetchAuthSignUpUser(queryBuilder.build());
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return result;
        }
        setIsLoading(false);
        return result;
    }

    const signUp = async (email: string, password: string, fullName: string, userType: number): Promise<boolean> => {
        if (isLogin) return true;
        setIsLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 500));
        const queryBuilder = new SignUpQueryBuilder();
        queryBuilder.setEmail(email);
        queryBuilder.setPassword(password);
        queryBuilder.setFullName(fullName);
        queryBuilder.setUserType(userType);
        const result = await fetchSignUpUser(queryBuilder.build());
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return false;
        }
        setIsLoading(false);
        setIsLogin(true);
        setIsConfirmed(result.data.isEmailConfirmed);
        const userData: User = {
            ...result.data,
            email: email,
            thumbnailImage: "",
            fontSize: 5,
            createdAt: String(Date.now())
        };
        setUser(userData);
        return true;
    }

    const signOut = async () => {
        if (!isLogin) return false;
        const result = await fetchSignOutUser();
        if (result.error) {
            setError(result.error);
            return false;
        }
        setIsLogin(false);
        setIsConfirmed(false);
        return true;
    }

    const confirmUser = async(confirmToken: string) => {
        if (!isLogin || isConfirmed) return false;
        setIsLoading(true);
        const result = await fetchConfirmEmailUser(confirmToken);
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return false;
        }
        if (result.data.status !== "success") {
            setIsLoading(false);
            return false;
        }
        setIsLoading(false);
        setUser({...user, isEmailConfirmed: true});
        setIsConfirmed(true);
        return true;
    }

    const updateUser = async(fullName: string, thumbnailImage: string, fontSize: number, csrf: string): Promise<boolean> => {
        if (!isLogin || !isConfirmed) return false;
        setIsLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 500));
        const queryBuilder = new UpdateUserQueryBuilder();
        queryBuilder.setFullName(fullName);
        queryBuilder.setFontSize(fontSize);
        queryBuilder.setThumbnailImage(thumbnailImage);
        queryBuilder.setCsrf(csrf);
        const result = await fetchUpdateUser(queryBuilder.build());
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return false;
        }
        setIsLoading(false);
        setUser({...user, ...result.data});
        return true;
    }

    const getRoomConfirmedUser = async(roomID: string): Promise<RoomUserEntity> => {
        if (!isLogin || !isConfirmed) return new RoomUserEmpty();
        setIsLoading(true);
        const queryBuilder = new GetRoomUserQueryBuilder();
        queryBuilder.setRoomID(roomID);
        const result = await fetchRoomUser(queryBuilder.build());
        if (result.error) {
            setIsLoading(false);
            setError(result.error);
            return result;
        }
        setIsLoading(false);
        return result;
    }

    // 初回のみログイン試行を行う
    useEffect(() => {
        cookieSignIn();
    }, [])

    return {
        isLogin, isConfirmed, isLoading, user, error,
        signIn, signUp, signOut, updateUser, confirmUser, signUpAuth, getRoomConfirmedUser
    }
}