import { GetRoomQuestionMessageEntity, GetRoomQuestionMessageError } from "./entity";
import { ResponseMapper } from "./responseMapper";
import { fetchUrl } from "../../../lib/const/url";

export async function fetchRoomQuestionMessage(query: any): Promise<GetRoomQuestionMessageEntity> {
    try {
        const response = await fetch(`${fetchUrl}/messages/room/questions`, {
            method: "POST",
            body: query,
            credentials: "include"
        }).then((data) => data.json());
        if (response.message) {
            return new GetRoomQuestionMessageError(response.message);
        }
        const entity = new ResponseMapper(response).convert();
        return entity;
    }catch (e) {
        console.log(e);
        return new GetRoomQuestionMessageError(JSON.stringify(e));
    }
}