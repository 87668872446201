export type UserStatus = {
    status: string
}

export class SignOutUserEntity {
    data: UserStatus;
    error?: string;
    constructor(data: UserStatus, error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

export class SignOutUserEmpty extends SignOutUserEntity {
    constructor() {
        super({status: "failed"})
    }
}

export class SignOutUserError extends SignOutUserEntity {
    constructor(errorMessage: string) {
        super({status: "failed"}, errorMessage)
    }
}