import { GetConfirmedRoomEntity, GetConfirmedRoomError } from "./entity"
import { ResponseMapper } from "./responseMapper"
import { fetchUrl } from "../../../lib/const/url"

export async function fetchConfirmedRoom(): Promise<GetConfirmedRoomEntity> {
    try {
        const response = await fetch(`${fetchUrl}/rooms/confirmed`, {
            method: "POST",
            credentials: "include"
        }).then((data) => data.json());
        if (response.message) {
            return new GetConfirmedRoomError(response.message);
        }
        const entity = new ResponseMapper(response).convert();
        return entity;
    } catch (e) {
        console.log(e);
        return new GetConfirmedRoomError(JSON.stringify(e));
    }
}