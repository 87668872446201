export type Opinion = {
    status: string;
}

export class CreateOpinionEntity {
    data: Opinion;
    error?: string;
    constructor(data: Opinion, error?: string) {
        this.data = data;
        if (error) this.error = error;
    }
}

export class CreateOpinionEmpty extends CreateOpinionEntity{
    constructor() {
        super({status: "unknown"});
    }
}

export class CreateOpinionError extends CreateOpinionEntity {
    constructor(errorString: string) {
        super({status: "fail"}, errorString)
    }
}