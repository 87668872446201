import { fetchUrl } from "../../../lib/const/url";
import { InviteEntity, InviteError } from "./entity";

export async function fetchInviteRoom(query: any) {
    try {
        const response = await fetch(`${fetchUrl}/roomJoins/invite`, {
            method: "POST",
            body: query,
            credentials: "include"
        }).then((data) => data.json());
        if (response.error) {
            return new InviteError(response.error);
        }
        const entity = new InviteEntity(response.data.Status);
        return entity;
    } catch(e) {
        console.log(e);
        return new InviteError(JSON.stringify(e));
    }
}