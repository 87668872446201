import {UpdateEntity, UpdateEmpty} from "./entity";
import { toInt } from "../../../lib/helper/valueCast";

export class ResponseMapper {
    private data;
    constructor(data: any) {
        this.data = data;
    }
    convert(): UpdateEntity {
        return this.data
        ? new UpdateEntity({
            fullName: String(this.data?.FullName),
            thumbnailImage: String(this.data?.ThumbnailImage),
            fontSize: toInt(this.data?.FontSize),
            csrf: String(this.data.Csrf)
        })
        : new UpdateEmpty();
    }
}