import React, {useRef, useEffect} from "react";

import { Message } from "../../../fetch/messages/getRoom/entity";
import { User as CookieUser } from "../../../fetch/users/cookieUser/entity";
import { User } from "../../../fetch/users/getRoomUser/entity";
import { CircularProgress } from "@mui/material";
import MessageComponent from "../Message/component";
import { Question } from "../../../fetch/questions/getAll/entity";

import "./style.css"

type Props = {
    roomID: string;
    messages: Message[];
    user: CookieUser;
    questions: Question[]
    confirmedUsers: User[];
    isMessageLoading: boolean;
    updateMessage: (roomID: string, messageID: number, content: string) => Promise<boolean>;
}

const Messenger: React.FC<Props> = ({
    roomID,
    messages,
    questions,
    user,
    confirmedUsers,
    isMessageLoading,
    updateMessage
}) => {
    const messengerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setTimeout(() => messengerRef.current?.scroll(0, messengerRef.current?.scrollHeight || 100000), 100);
    }, [])
    return (
        <div className="Messenger" ref={messengerRef}>
            { isMessageLoading
                ? <CircularProgress/>
                :
                <>
                    {messages.map((message) => {
                        return (
                        <MessageComponent
                            key={message.id}
                            roomID={roomID}
                            message={message}
                            questions={questions}
                            confirmedUsers={confirmedUsers}
                            currentUserID={user.id}
                            updateMessage={updateMessage}
                        />)
                    })}
                </>
            }
        </div>
    );
}

export default Messenger;