import { CreateMessageEmpty, CreateMessageEntity } from "./entity";
import { toInt } from "../../../lib/helper/valueCast";

export class ResponseMapper{
    private data: any;
    constructor(data: any) {
        this.data = data;
    }
    convert(): CreateMessageEntity {
        return this.data
        ? new CreateMessageEntity({
            id: toInt(this.data?.ID),
            userID: String(this.data?.UserID),
            questionID: toInt(this.data?.QuestionID),
            imageUrl: String(this.data?.ImageUrl),
            content: String(this.data?.Content),
            createdAt: String(this.data?.CreatedAt)
        })
        : new CreateMessageEmpty()
    }
}